.parkhome-pagecontent {
	padding: 0 0 50px 0;

	.parkhome-bnrsec {
		background-color: #111827;
		padding: 50px 0;
	}

	.ecosystem-sec {
		padding: 50px 0 0 0;

		.ecosys-linearbg {
			position: relative;
			background-color: #FFF;
			box-shadow: 0px 6px 54px 0px #E2F0FF;
			backdrop-filter: blur(5px);
			padding: 30px 40px 30px 20px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('../images/ecobg.png');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
				opacity: 0.4;
				z-index: -1;
			}


		}

		.ecosystem-cnt {
			.ecosys-subhead {
				color: #111827;
				font-size: 30px;
				font-style: normal;
				font-weight: 275;
				line-height: 28px;
				margin: 0 0 5px 0;
			}

			.ecosys-head {
				color: #111827;
				font-size: 36px;
				font-style: normal;
				font-weight: 600;
				line-height: 45px;
			}

			.ecosys-para {
				color: rgba(17, 24, 39, 0.60);
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
			}

		}
	}


	@media only screen and (max-width: 991px) {

		.ecosys-linearbg {
			.ecolinear-imgcol {
				order: 2;
				margin: 25px 0 0 0;
			}
		}

	}

	.subsub-head 
	{
		color: #111827;
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: 40px;
	}
}