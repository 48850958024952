/*--||
Common Use Start
||--*/
* {
    box-sizing: border-box;
}

:root {
    --font-poppins: 'Poppins', sans-serif;
}

img {
    max-width: 100%;
}

body {
    background-color: #ffffff;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    color: #111827;
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

    .container-974 {
        max-width: 1004px;
    }
}



.pgLoad {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0F0F0F;
    z-index: 999999;
}



.form-control:focus {
    box-shadow: none;
}


.btnIc {
    padding: 0px;
}

.btnIc:focus {
    box-shadow: none;
}

.btn:focus {
    box-shadow: none;
}

/*--||
Common Use End
||--*/



/*- ==================================================|| Home page content start ||====================================== -*/


/*--||
Header Content Start
||--*/
.indHd {
    transition: 0.3s;
    padding: 0px 0px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 9999;
    background-color: #111827;
}

header.indHd.hdStk {
    background-color: #111827;
}

.indHdTp {
    background-color: rgba(255, 255, 255, 0.04);
    padding: 5px 0px;
}

.hdNavMenu {
    margin: 0px auto;
}

.hdNavMenu .nav-link {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    font-family: var(--font-poppins);
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.hdNavMenu .nav-link:hover,
.hdNavMenu .nav-link.active {
    color: #6987ff;
}

.hdNavMenu .nav-link[data-toggle="dropdown"]::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1L4.5 5L8.5 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    margin-left: 4px;
    background-position: center;
}

.hdNavMenu .nav-item {
    margin: 0px 19.5px;
}

.hdNavMenu .dropdown-menu {
    background-color: #fff;
    min-width: 185px;

    .dropdown-item {
        color: #484848;
        padding: 10px 16px;
        font-size: 14px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    &.lgMenu {
        min-width: 200px;
        //min-height:300px;
        overflow-y: auto;

        li {
            &.subMenuHeading {
                font-size: 15px;
                font-weight: 600;
                color: #000;
                padding: 5px 16px;
            }
        }

        a {
            &.dropdown-item {
                padding: 5px 16px;

                &:hover {
                    background: transparent;
                    color: #456AFF;
                    cursor: pointer;
                }
            }
        }
    }
}

.hdNavMenu .btn-secondary {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    font-family: var(--font-poppins);
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: transparent;
    border: none;

    &:hover {
        color: #6987ff;
    }
}

.hdNavMenu .btn-secondary::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1L4.5 5L8.5 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    margin-left: 4px;
    background-position: center;
}

.hdNavMenu .btn-secondary:not(:disabled):not(.disabled).active,
.hdNavMenu .btn-secondary:not(:disabled):not(.disabled):active,
.show>.hdNavMenu .btn-secondary.dropdown-toggle {
    background: transparent;
    color: #6987ff;
    box-shadow: none;
}

// .hdNavMenu {
//     margin: 0px auto;
// }

// .hdNavMenu .nav-link {
//     color: #ffffff;
//     font-weight: 400;
//     font-size: 14px;
//     font-family: var(--font-poppins);
//     padding-left: 0px !important;
//     padding-right: 0px !important;
// }

.hdNavMenu .nav-link:hover,
.hdNavMenu .nav-link.active {
    color: #6987ff;
}

.hdNavMenu .dropdown-menu a {
    color: #000000c0 !important;
    text-decoration: none !important;
}

// .hdNavMenu .nav-link[data-toggle="dropdown"]::after {
//     content: '';
//     display: inline-block;
//     vertical-align: middle;
//     width: 10px;
//     height: 7px;
//     background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1L4.5 5L8.5 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
//     background-repeat: no-repeat;
//     margin-left: 4px;
//     background-position: center;
// }

// .hdNavMenu .nav-item {
//     margin: 0px 19.5px;
// }

// .hdNavMenu .dropdown-menu {
//     background-color: #fff;
//     min-width: 185px;
// }

// .hdNavMenu .dropdown-menu .dropdown-item {
//     color: #484848;
//     padding: 10px 16px;
//     font-size: 14px;
// }

.hdNavMenu .dropdown-menu .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

// .hdNavMenu .btn-secondary {
//     color: #ffffff;
//     font-weight: 400;
//     font-size: 14px;
//     font-family: var(--font-poppins);
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//     background: transparent;
//     border: none;

//     &:hover {
//         color: #6987ff;
//     }
// }

// .hdNavMenu .btn-secondary::after {
//     content: '';
//     display: inline-block;
//     vertical-align: middle;
//     width: 10px;
//     height: 7px;
//     background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 1L4.5 5L8.5 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
//     background-repeat: no-repeat;
//     margin-left: 4px;
//     background-position: center;
// }

.hdNavMenu .btn-secondary:not(:disabled):not(.disabled).active,
.hdNavMenu .btn-secondary:not(:disabled):not(.disabled):active,
.show>.hdNavMenu .btn-secondary.dropdown-toggle {
    background: transparent;
    color: #6987ff;
    box-shadow: none;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #596fc7;
    color: #b7b2b2;
}

.hdRt {
    text-align: center;
    min-width: 183px;
}

.hdRt>*:not(:first-child) {
    margin-left: 8px;
}



.btn-12040 {
    min-width: 129px;
    font-weight: 500;
    font-size: 16px;
    padding: 9px 12px;
    border-radius: 4px;
}

.btn-bluFl {
    background-color: #456AFF;
    border-color: #456AFF;
    color: #ffffff;
}

.btn-bluFl:hover {
    background-color: transparent;
    border-color: #456AFF;
    color: #ffffff;
}

.btn-bluFll {
    background-color: #456AFF;
    border-color: #456AFF;
    color: #ffffff;
}

.btn-bluFll:hover {
    background-color: transparent;
    border-color: #456AFF;
    color: #121111;
}

// @media only screen and (min-width: 400px) {
//     .hdrLogo-ltr {
//         display: none;
//     }
// }

/* ----- Mobile Menu Toggle Start ----- */
.mobMenu {
    display: inline-block;
    vertical-align: middle;
}

.smClose {
    width: 40px;
    height: 40px;
    z-index: 9999999;
    overflow: hidden;
}

#closeicon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: scale(2) translate(0px, -1px);
    position: relative;
}

#closeicon path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: #456AFF;
    stroke-dashoffset: 0px;
}

#closeicon path#top,
#closeicon path#bottom {
    stroke-dasharray: 240px 950px;
}

#closeicon path#middle {
    stroke-dasharray: 240px 238px;
}

.smClose.active #closeicon path#top,
.smClose.active #closeicon path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
}

.smClose.active #closeicon path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
}


/* ----- Mobile  Menu Toggle End  ----- */
// @media only screen and (max-width: 991px) {
//     .hdNavMenu .nav-item {
//         margin: 6px 19.5px;
//     }

//     .hdRt {
//         min-width: auto;
//     }

//     .hdNavMenu {
//         position: fixed;
//         top: 0px;
//         left: 0px;
//         width: 260px;
//         height: 100%;
//         background-color: #111827;
//         box-shadow: 4px 0px 36px rgba(0, 0, 0, 0.16);
//         transform: translateX(-100%);
//         transition: 0.3s;
//         z-index: 999;
//         padding: 10px;
//     }

//     .sbMenu .hdNavMenu {
//         transform: translateX(0%);
//     }
// }

@media only screen and (max-width: 399px) {
    .hdrLogo {
        display: none;
    }

    .indHd .navbar-brand {
        margin-right: 0px;
    }

    .mobMenu {
        margin-left: 4px;
    }

    .btn-12944 {
        font-size: 14px;
        min-width: 99px;
        padding: 6px 12px;
    }
}

@media only screen and (max-width: 349px) {
    .btn-12040 {
        font-size: 14px;
        min-width: 119px;
        padding: 6px 10px;
    }

    .indHd .navbar-brand img {
        max-width: 120px;
    }
}

/*--||
Header Content End
||--*/



/*--||
Banner Content Start
||--*/
.bnrRow {
    min-height: 646px;
    align-items: center;
}

.bnr {
    background-color: #111827;
    color: #ffffff;
    overflow: hidden;
}

.bnrTx h2 {
    font-weight: 275;
    font-size: 30px;
    line-height: 28px;
    color: #ffffff;
    padding-bottom: 5px;
}

.bnrTx h1 {
    font-weight: 600;
    font-size: 80px;
    line-height: 80px;
}

.bnrTx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
}

.bnrLks {
    padding: 4px 0px 0px;
    margin: 0px -6.5px;
}

.bnrLks>* {
    margin: 10px 6.5px 0px;
}

.btn-wtnOl {
    border-color: #ffffff;
    color: #ffffff;
}

.btn-wtnOl:hover {
    background-color: #456aff;
    color: #ffffff;
    border-color: #456aff;
}

.bnrTx {
    margin-bottom: 3.5rem;
}




.bnrCol-rt {
    align-self: flex-end;
    padding-bottom: 32px;
}

.bnrIg img {
    transform: scale(1.08);
}

@media only screen and (max-width: 1199px) {
    .bnrIg img {
        transform: scale(1.25);
        transform-origin: bottom;
    }

    .bnrRow {
        min-height: 596px;
    }
}

@media only screen and (max-width: 991px) {

    .hdNavMenu {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 260px;
        height: 100%;
        background-color: #111827;
        box-shadow: 4px 0px 36px rgba(0, 0, 0, 0.16);
        transform: translateX(-100%);
        transition: 0.3s;
        z-index: 999;
        padding: 10px;
    }


    .bnrRow {
        flex-direction: column-reverse;
    }

    .bnrIg img {
        transform: scale(1);
        transform-origin: center;
    }

    .bnrCol-rt {
        padding: 44px 0px;
    }

    .bnrIg {
        text-align: center;
        margin-bottom: -4rem;
    }

    .bnrIg svg {
        max-width: 100%;
        height: auto;
    }

    .bnrTx {
        text-align: center;
        position: relative;
        z-index: 9;
    }

    .bnrTx h1 {
        font-size: 70px;
        line-height: 74px;
    }
}

@media only screen and (max-width: 767px) {
    .bnrTx h1 {
        font-size: 60px;
        line-height: 64px;
    }
}

@media only screen and (max-width: 479px) {
    .bnrTx h2 {
        font-size: 26px;
        line-height: 24px;
    }

    .bnrTx h1 {
        font-size: 50px;
        line-height: 56px;
    }
}

@media only screen and (max-width: 399px) {
    .bnrTx h2 {
        font-size: 24px;
        line-height: 20px;
    }

    .bnrTx h1 {
        font-size: 40px;
        line-height: 46px;
    }

    .btn-12040 {
        font-size: 14px;
        min-width: 119px;
    }
}

/*--||
Banner Content End
||--*/


/*--||
Project Counter Start
||--*/
.prjCnt {
    padding: clamp(3rem, 8vw, 6rem) 0px;
}

.prjCon {
    position: relative;
}

.prjCon::before {
    content: '';
    background-image: url('../assets/images/counter-bg.png');
    width: calc(100% - 26px);
    height: 100%;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    border-radius: 4px;
    background-position: center;
    opacity: 0.5;
    filter: blur(22px);
}

.prjCon::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF -15.35%, rgba(255, 255, 255, 0.92) 100%);
    opacity: 0.6;
    backdrop-filter: blur(5px);
    border-radius: 4px;
}

.prjConR {
    position: relative;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    margin: 0px -27.5px;
    padding: 15px;
}

.prjConC {
    padding: 15px 27.5px;
    text-align: center;
}

.prjNb {
    font-weight: 600;
    font-size: 32px;
    line-height: 28px;
    color: #111827;
    margin-bottom: 9px;
}

.prjNb span {
    font-weight: 300;
}

.prjTx {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #111827;
}

@media only screen and (max-width: 1199px) {
    .prjConR {
        margin: 0px -22.5px;
    }

    .prjConC {
        padding: 15px 22.5px;
    }
}

@media only screen and (max-width: 991px) {
    .prjConR {
        margin: 0px -15px;
    }

    .prjConC {
        flex: 0 0 50%;
        padding: 15px;
    }

    .prjNb {
        font-size: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .prjTx {
        font-size: 14px;
    }

    .prjNb {
        font-size: 28px;
        margin-bottom: 4px;
    }

}

@media only screen and (max-width: 479px) {
    .prjConC {
        flex: 0 0 100%;
    }

    .prjNb {
        font-size: 26px;
    }
}

/*--||
Project Counter End
||--*/



/*--||
Keyfeatures Content Start
||--*/
.kyFtr {
    padding: clamp(3rem, 8vw, 6rem) 0px 39px;
    position: relative;
    overflow: hidden;
}

.secTl {
    text-align: center;
}

.secTl h3 {
    font-weight: 275;
    font-size: 30px;
    line-height: 28px;
    color: #111827;
    padding-bottom: 8px;
}

.secTl h2 {
    font-weight: 600;
    font-size: 60px;
    line-height: 44px;
    color: #111827;
}

.kyFtrR {
    margin-right: -10px;
    margin-left: -10px;
    position: relative;
}

.kyFtrC {
    padding: 39px 10px;
}

.kyFtrCon {
    min-height: 316px;
    border-radius: 4px;
    padding: 1.5rem;
    text-align: center;
    position: relative;
    height: 100%;
    transition: 0.3s transform;
}

.kyFtrCon:hover {
    transform: translateY(-5px);
}

.kyFtrCon::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    opacity: 0.86;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(3px);
}

.kyFtrCon>* {
    position: relative;
}

.kyFtrIc {
    padding: 22px 0px;
}

.kyFtrCon h2 {
    font-size: 20px;
    line-height: 28px;
    color: #111827;
    padding: 5px 0px;
}

.kyFtrCon p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
}

.kyFtr .secTl {
    padding-bottom: 30px;
}


.kyFtrROl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
}

.kyFtrROl img {
    opacity: 0.3;
    transform: rotate(-13.77deg);
}

@media only screen and (max-width: 991px) {
    .kyFtrC {
        padding: 10px 10px;
    }

    .kyFtrR {
        justify-content: center;
    }

    .secTl h2 {
        font-size: 50px;
        line-height: 1.125;
    }

    .secTl h3 {
        font-size: 20px;
        line-height: normal;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .kyFtr {
        padding: clamp(3rem, 8vw, 6rem) 0px;
    }
}

@media only screen and (max-width: 767px) {
    .kyFtr .secTl {
        padding-bottom: 10px;
    }

    .kyFtrCon {
        min-height: auto;
    }

    .kyFtrROl {
        display: none;
    }

    .secTl h2 {
        font-size: 40px;
    }

    .secTl h3 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 479px) {
    .secTl h3 {
        font-size: 16px;
    }

    .secTl h2 {
        font-size: 36px;
    }


    .kyFtrIc {
        padding: 12px 0px;
    }

    .kyFtrCon h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 399px) {
    .secTl h2 {
        font-size: 32px;
    }
}

/*--||
Keyfeatures Content End
||--*/



/*--||
Eco System Content Start
||--*/
.pkEco {
    padding: clamp(30px, 7vw, 78px) 0px;
}

.pkEco .secTl {
    padding-bottom: 4px;
}

.ecoTab .nav {
    padding: 30px 0px;
    justify-content: center;
}

.ecoTab .nav .nav-item {
    padding: 0px 12px;
}

.ecoTab .nav .nav-link {
    background-color: #F4F4F4;
    border-radius: 4px;
    border: 0px;
    padding: 8px 8px;
    outline: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.ecoTab .nav .nav-link img {
    max-width: 34px;
}

.ecoTab .nav .nav-link.active,
.ecoTab .nav .nav-link:hover {
    background-color: #6987FF;
}

.ecoTab .nav .nav-link.active img,
.ecoTab .nav .nav-link:hover img {
    filter: brightness(20);
}



.ecoTbCon {
    background-color: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    padding: 34px 50px;
}

.ecoTabR {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px -7.5px;
}

.ecoTabC.lt {
    flex: 0 0 65%;
}

.ecoTabC.rt {
    flex-grow: 1;
}

.ecoTabC {
    padding: 0px 7.5px;
}

.ecoTabTx h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
    padding-bottom: 5px;
}

.ecoTabTx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    margin: 0px;
}

@media only screen and (max-width: 991px) {
    .ecoTbCon {
        padding: 30px 30px;
    }

    .ecoTabC.rt {
        flex: 0 0 35%;
    }
}

@media only screen and (max-width: 575px) {
    .ecoTab .nav {
        padding: 20px 0px;
    }

    .ecoTabR {
        flex-direction: column-reverse;
    }

    .ecoTabC.rt {
        flex: 0 0 100%;
        margin-bottom: 1rem;
    }

    .ecoTabTx h5 {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 479px) {
    .ecoTab .nav .nav-link img {
        max-width: 24px;
    }

    .ecoTabTx h5 {
        font-size: 18px;
        margin-bottom: 6px;
    }

    .ecoTbCon {
        padding: 20px 20px;
    }
}

@media only screen and (max-width: 399px) {
    .ecoTab .nav .nav-item {
        padding: 0px 8px;
    }
}

/*--||
Eco System Content End
||--*/



/*--||
Developer Resource Content Start
||--*/
.devRs {
    padding: clamp(30px, 7vw, 78px) 0px;
}

.devRsC {
    padding: 10px 10px;
}

.devRsCon {
    padding: 44px 40px;
    background-color: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    height: 100%;
    transition: 0.3s transform;
}

.devRsCon:hover {
    transform: translateY(-10px);
}

.devRsIc {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F6F6;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin-bottom: 13px;
}

.devRsCon h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
    padding-bottom: 5px;
}

.devRsCon p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    margin-bottom: 2px;
}

.devRsR {
    padding-top: 30px;
}

@media only screen and (max-width: 991px) {
    .devRsR {
        padding-top: 10px;
    }
}

@media only screen and (max-width: 479px) {
    .devRsCon {
        padding: 34px 30px;
    }

    .devRsCon h5 {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 349px) {
    .devRsCon {
        padding: 24px 25px;
    }
}

/*--||
Developer Resource Content End
||--*/



/*--||
Get Started Content Start
||--*/
.gtStR {
    align-items: center;
    justify-content: center;
}

.gtStTx .secTl {
    text-align: left;
    padding-bottom: 18px;
}

.gtStTx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    margin: 0px;
}

.gtStIg {
    text-align: center;
}

.gtSt {
    padding: clamp(30px, 6vw, 64px) 0px;
}

@media only screen and (max-width: 991px) {
    .gtStTx .secTl {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .gtStR {
        flex-direction: column-reverse;
    }
}

/*--||
Get Started Content End
||--*/



/*--||
Features Content Start
||--*/
.ftrsGd {
    display: grid;
    grid-template-columns: 368px auto;
    gap: 16px 21px;
    grid-template-rows: auto auto auto;
}

.ftrsC-1 {
    grid-column: 1/2;
    grid-row: 1/3;
}

.ftrsC-2 {
    grid-column: 2/3;
    grid-row: 1/2;
}

.ftrsC-3 {
    grid-column: 1/2;
    grid-row: 3/4;
}

.ftrsC-4 {
    grid-column: 2/3;
    grid-row: 2/4;
}

.ftrsBx {
    background-color: #E8D6FF;
    border-radius: 4px;
    padding: 50px 26px 37px 35px;
    height: 100%;
}

.ftrsBx.blu {
    background-color: #C2E9FF;
}

.ftrsBx.gr {
    background-color: #D7FFD0;
}

.ftrsBx h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
    padding-bottom: 4px;
}

.ftrsBx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
}


.ftrsBx.adShp {
    max-width: 274px;
    padding: 0px 0px 0px 0px;
}



.ftrsTx {
    text-align: right;
    padding-bottom: 22px;
    position: relative;
}

.ftrsTx .secTl {
    text-align: right;
}

.ftrsTx .secTl h2 {
    padding-bottom: 8px;
}

.ftrsTx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
}

.ftrs {
    padding: clamp(30px, 6vw, 62px) 0px;
}

.ftrsTxIg {
    position: absolute;
    bottom: 20%;
    left: 16%;
}



.adShpHd {
    position: relative;
    margin-bottom: 7px;
}

.adShpIcBx img {
    max-width: 92px;
}

.adShpIcBx {
    text-align: right;
}

.adShpIc {
    position: absolute;
    background: #FFC940;
    border-radius: 6px;
    width: 92px;
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -22px;
    top: -22px;
}

.adShpIc img {
    max-width: 50%;
}

.adShpBd {
    padding: 0px 14px 2px 28px;
}

@media only screen and (max-width: 991px) {
    .ftrsGd {
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
    }

    .ftrsC-1 {
        order: 3;
    }

    .ftrsC-2 {
        order: 1;
    }

    .ftrsC-3 {
        order: 4;
    }

    .ftrsC-4 {
        order: 2;
    }

    .ftrsBx.adShp {
        max-width: 100%;
    }

    .ftrsC {
        padding: 0.5rem 0px;
        width: 100%;
    }

    .ftrsTxIg {
        display: none;
    }

    .ftrsTx .secTl {
        text-align: center;
        padding-bottom: 0.75rem;
    }

    .ftrsTx .secTl h2 {
        padding-bottom: 0px;
    }

    .ftrsTx {
        text-align: center;
        padding-bottom: 0px;
    }

    .ftrsTx p br {
        display: none;
    }

    .adShpHd {
        display: none;
    }

    .ftrsBx,
    .ftrsBx.adShp {
        padding: 40px 26px 24px 26px;
    }

    .adShpBd {
        padding: 0px;
    }
}

@media only screen and (max-width: 479px) {

    .ftrsBx,
    .ftrsBx.adShp {
        padding: 30px 26px 14px 26px;
    }
}

/*--||
Features Content End
||--*/



/*--||
Scale width Content Start
||--*/
.sclWthR {
    align-items: center;
}

.sclWthIg {
    text-align: center;
}

.sclWthC.lt .sclWthCon {
    text-align: right;
}

.sclWthCon {
    padding: 18px 0px;
}

.sclWthCon h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
    padding-bottom: 6px;
}

.sclWthCon p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    margin: 0px;
}

.sclWth {
    padding: clamp(30px, 6vw, 52px) 0px;
}

.sclWthTx .secTl h2 {
    padding-bottom: 8px;
}

.sclWthTx p {
    max-width: 629px;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    text-align: center;
    margin: 0px auto;
    padding: 0.5rem 0px;
}

.sclWthTx {
    margin-bottom: 22px;
}

@media only screen and (max-width: 991px) {
    .sclWthC.ct {
        order: 1;
    }

    .sclWthC.lt {
        order: 2;
    }

    .sclWthC.rt {
        order: 3;
    }

    .sclWthR {
        align-items: flex-start;
    }

    .sclWthC.lt .sclWthCon {
        text-align: left;
    }

    .sclWthTx .secTl h2 {
        padding: 0px;
    }

    .sclWthTx {
        margin-bottom: 0px;
    }

    .sclWthIg {
        margin-bottom: 22px;
    }

    .sclWthC {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sclWthCon {
        flex: 0 0 50%;
        padding: 18px 15px;
    }
}

@media only screen and (max-width: 575px) {
    .sclWthCon {
        flex: 0 0 100%;
        padding: 10px 15px;
    }

    .sclWthCon h5 {
        padding-bottom: 0px;
    }
}

@media only screen and (max-width: 399px) {
    .sclWthCon {
        padding: 10px 5px;
    }
}

/*--||
Scale width Content End
||--*/



/*--||
Part of community Content Start
||--*/
.ptCmtCnt {
    max-width: 562px;
    margin: 0 auto;
    padding: 34px 0px;
}


.ptCmtR {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -11px;
}

.ptCmtC {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 13.5px 11px;
}

.ptCmtC a {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #111827;
    transition: 0.3s transform;
}

.ptCmtC a:hover {
    transform: translateY(-10px);
}

.ptCmtC a::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #F4F4F4;
    opacity: 0.86;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.ptCmtC a>* {
    position: relative;
}

.ptCmtC a img {
    margin-right: 7px;
}

.secTl h2 span {
    font-weight: 275;
}

.ptCmt {
    padding: 50px 0px;
}

@media only screen and (max-width: 991px) {
    .ptCmtCnt {
        padding: 4px 0px 14px;
    }
}

@media only screen and (max-width: 767px) {
    .ptCmt {
        padding: 40px 0px;
    }
}

@media only screen and (max-width: 479px) {
    .ptCmtR {
        margin: 0px -6.5px;
    }

    .ptCmtC {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 6.5px 6.5px;
    }

    .ptCmt {
        padding: 30px 0px;
    }

    .ptCmtC a {
        min-height: 50px;
    }
}

/*--||
Part of community Content End
||--*/



/*--||
Footer Content Start
||--*/
.indFtr {
    background-color: #F6F6F6;
    padding-top: 45px;
}

.ftLogo {
    margin-bottom: 23px;
}

.ftCon p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.62);
    margin: 0 0 10px 0;
}

.ftCon {
    padding-bottom: 10px;
}

.ftScl h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #111827;
}

.ftScl ul {
    margin: 0px -6px;
    padding: 6px 0px 0px;
    list-style: none;
}

.ftScl ul li {
    padding: 0px 6px;
    display: inline-block;
}

.ftScl ul li a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #262F43;
    border: 1px solid rgba(255, 255, 255, 0.37);
    border-radius: 50%;
    transition: 0.5s background-color;
}

.ftScl ul li a:hover {
    background-color: #456aff;
    border-color: #456aff;
}

.ftMenu h6 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #111827;
    position: relative;
}

.ftMenu h6::after {
    content: '';
    width: 27px;
    height: 1px;
    background-color: rgba(17, 24, 39, 0.27);
    position: absolute;
    bottom: -14px;
    left: 0px;
}

.ftMenu ul {
    list-style: none;
    padding: 16px 0px 12px;
}

.ftMenu ul li {
    padding: 10px 0px;
}

.ftMenu ul li a {
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #111827;
    text-decoration: none;
    display: inline-block;
    width: 100%;
}

.ftMenu ul li a:hover {
    text-shadow: 0px 0px 0px #111827, 0px 0px 0px #111827;
}


.ftRow {
    justify-content: space-between;
}

.ftCp {
    border-top: 1px solid rgba(109, 109, 109, 0.29);
    text-align: center;
    color: #4D4D4D;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    padding: 18px 0px;
    margin-top: 5px;
}

@media only screen and (max-width: 991px) {
    .indFtr {
        padding-top: 25px;
    }

    .ftCol {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .ftMenu ul {
        margin: 0px;
    }

    .ftCon {
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 479px) {
    .ftMenu h6::after {
        bottom: -6px;
    }

    .ftMenu ul {
        padding: 6px 0px 0px;
    }

    .ftMenu ul li {
        padding: 6px 0px;
    }
}

/*--||
Footer Content End
||--*/


/* Banner Animation  Start */

.low-color-stroke-lt {
    stroke: #F3D2FF;
    animation: low-color-stroke-lt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes low-color-stroke-lt {
    0% {
        stroke: #FFDAC1;
    }

    100% {
        stroke: #F3D2FF;
    }
}

.high-color-stroke-lt path {
    stroke: #D21EFF;
    animation: high-color-stroke-lt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes high-color-stroke-lt {
    0% {
        stroke: #F9955A;
    }

    100% {
        stroke: #D21EFF;
    }
}

.glow-stroke-white-lt path {
    stroke: #ffffff;
}

.glow-stroke-color-lt path {
    stroke: #EA21FF;
    animation: glow-stroke-color-lt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes glow-stroke-color-lt {
    0% {
        stroke: #FF6E1F;
    }

    100% {
        stroke: #EA21FF;
    }
}



.low-color-stroke-rt {
    stroke: #CEF6FF;
    animation: low-color-stroke-rt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes low-color-stroke-rt {
    0% {
        stroke: #CEF6FF;
    }

    100% {
        stroke: #CEF6FF;
    }
}

.high-color-stroke-rt path {
    stroke: #3B9EBD;
    animation: high-color-stroke-rt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes high-color-stroke-rt {
    0% {
        stroke: #3BBD6F;
    }

    100% {
        stroke: #3B9EBD;
    }
}

.glow-stroke-white-rt path {
    stroke: #ffffff;
}

.glow-stroke-color-rt path {
    stroke: #00BDFF;
    animation: glow-stroke-color-rt 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes glow-stroke-color-rt {
    0% {
        stroke: #58DE37;
    }

    100% {
        stroke: #00BDFF;
    }
}

.right-side-img-2 {
    animation: right-side-image-2 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes right-side-image-2 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.right-side-img-1 {
    animation: right-side-image-1 3s ease-in-out 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes right-side-image-1 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.left-side-img-2 {
    animation: left-side-image-2 3s ease-in-out 0;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes left-side-image-2 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


.left-side-img-1 {
    animation: left-side-image-1 3s ease-in-out 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}

@keyframes left-side-image-1 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/*ssstyle*/

.pkchinsec {
    min-height: calc(100vh - 462px);
}

.pksidebr {
    background: #F6F6F6;
    padding: 26px;
    width: 300px;
    height: calc(100% - 97px);
    margin-top: 21px;
    position: fixed;
    top: 76px;
}

.pksidebr .sidenav a,
.pksidebr .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.sidenav ul li a,
.sidenav ul li span {
    font-weight: 400;
    font-size: 14px;
    color: #484848;
    line-height: 28px;
}

.sidenav ul li a.active,
.sidenav ul li a:hover,
.sidenav ul li a.active-scroll-spy {
    color: #6987FF;
}

.sidenav ul li {
    list-style: none;
}

.sidenav ul li span {
    color: #6987FF;
}

.sidenav {
    .dropdown-menu {
        &.dropdown-container {
            position: static !important;
            background: transparent;
            border: none;
            transform: none !important;

            .dropdown-item {
                font-weight: 400;
                font-size: 14px;
                color: #484848;
                line-height: 28px;
            }
        }
    }


}

.pksidebr {
    &.SideMenuOpen {
        transform: translateX(0);
    }
}

/*kpmainsec*/
.pkmainsec {
    margin-top: 21px;
    padding-left: 20px;
    width: 100%;
    margin-bottom: 40px;
    padding-left: 315px;
}

.pkmainsec li::marker {
    color: #484848 !important;
}

.pkchainmn li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #484848;
}

.pkchainmn li a span {
    color: #6987FF;
}

.pkmenu2 li a {
    font-weight: 400;
    font-size: 14px;
    color: #484848;
    line-height: 26px;
}

.pkmenu2 li {
    margin-bottom: 10px;
}

.pkmenu2 .parlevm {
    list-style: none;
    padding-bottom: 18px;
    line-height: 30px;
    padding-top: 18px;
}

.pkchinsec {
    .sidebarCnt {
        overflow: hidden;
    }
}

.pksidebr {
    @media(max-width:991px) {
        transform: translateX(-100%);
        transition: all 0.5s;
        position: fixed;
        transition: all 0.5s;
        position: fixed;
        top: 76px;
        left: 0px;
        z-index: 9999;
        margin-top: 0px;
        height: calc(100% - 76px);
        overflow-y: auto;
    }
}

.pkmainsec {
    @media(max-width:991px) {
        width: 100%;
        padding-left: 0px;
    }
}

/*headings*/
.kphedone {
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    color: #111827;
    margin-bottom: 21px;
}

.kphedtwo {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #111827;
}

.kphedtwo.fnbold {
    font-weight: 600;
    padding-bottom: 5px;
}

.kphedthree {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #111827;
    margin-bottom: 12px;
}

.kphedfour {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #111827;
}

.kphedfive {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #111827;
}

.kpparaone {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #484848;
    margin-bottom: 20px;
}

.pkmainsec {
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #484848;
        margin-bottom: 20px;
    }

    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #484848;
    }

    h2 {
        font-style: normal;
        // font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        color: #111827;
    }
}

.blutext {
    color: #0F53FE;
    font-weight: 600;
}

.ulboldtxt {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #111827;
}

/*kpgaming*/

.kpgamebanner {
    background: url(/assets/images/pics/kpbanner.png) no-repeat;
    background-size: 100% 100%;

}

.kpgamehead {
    font-weight: 600;
    font-size: 78px;
    line-height: 80px;
    color: #FFFFFF;
}

.kpgamehead h2 {
    // font-weight: 600;
    font-size: 78px;
    line-height: 80px;
    color: #FFFFFF;
}

.kpgamepara {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #FFFFFF;
}

.ethgamecnt {
    background: rgba(255, 255, 255, 0.8);
    opacity: 0.86;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    padding: 53px;
}

.ethgamekit {
    background: url(/assets/images/pics/maskbg.png) no-repeat;
    background-size: 100% 100%;
    padding: 48px;

}

.ethgamekit h2 {
    text-align: center;
}

/*.ethgamekit{
    background: url(/assets/images/pics/ethkit.png) no-repeat;
    min-height: 337px;
    background-size: 100% 100%;
    position: relative;
    padding: 47px 100px;
}
.ethgamecnt{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}*/
.kpkithead {
    font-weight: 600;
    font-size: 40px;
    line-height: 28px;
    color: #111827;
    text-align: center;
    padding-bottom: 10px;
}

.kpkitpara {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-align: center;
    color: rgba(17, 24, 39, 0.6);
}

.whyeth h4 {
    font-weight: 275;
    font-size: 30px;
    line-height: 28px;
    color: #111827;
}

.whyeth h3 {
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    color: #111827;
}

.ignHead {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #111827;
}

.ignPara {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);

}

.pdbtm {
    h3 {

        font-size: 40px;
        line-height: 50px;
        color: #111827;
    }

    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: rgba(17, 24, 39, 0.6);
    }

    h2 {
        font-size: 40px;
        line-height: 28px;
        color: #111827;
        text-align: center;
        padding-bottom: 10px;
    }
}


.ignImage img {
    width: 100%;
    height: 320px;
    object-fit: cover;

}

.ignImage {
    position: relative;
    max-width: 80%;
    margin: auto;
}

.ignImage:after {
    position: absolute;
    content: '';
    width: calc(100% + 40px);
    height: calc(100% + 55px);
    top: -30px;
    left: -34px;
    background: url(/assets/images/pics/line1.png) no-repeat;
    background-size: 83% 100%;
}

.ignImage1 {
    position: relative;
    max-width: 80%;
    margin: auto;
}

.ignImage1:after {
    position: absolute;
    content: '';
    width: calc(100% + 40px);
    height: calc(100% + 55px);
    top: -29px;
    right: -113px;
    background: url(/assets/images/pics/line2.png) no-repeat;
    background-size: 83% 100%;
}

.pdbtm {
    margin-bottom: 100px;
}



/*defi*/
.defibanner {

    background: url(/assets/images/pics/defibg.png) no-repeat;
    background-size: 100% 100%;
    padding: 70px;
}

.defihd {
    font-weight: 275;
    font-size: 30px;
    line-height: 28px;
    color: #FFFFFF;
}

.xxframe {
    font-weight: 275;
    font-size: 24px;
    color: #111827;
}

.lastCon {
    h4 {
        font-weight: 275;
        font-size: 24px;
        color: #111827;
    }

    h3 {
        font-size: 30px;
        line-height: 35px;
    }
}

/*kppayment*/

.kppaybanner {
    background: url(/assets/images/pics/kppaybg.png) no-repeat;
    background-size: 100% 100%;
    padding: 90px;
}

.kpbuldtxt {
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    color: #111827;
}

.kpbuldtxt h2 {
    // font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    color: #111827;
}

.container .lastCon {
    h2 {
        font-size: 60px;
        line-height: 80px;
        color: #111827;
    }

    h3 {
        // font-weight: 600;
        font-size: 40px;
        line-height: 50px;
        color: #111827;
    }

    h4 {
        font-weight: 275;
        font-size: 24px;
        color: #111827;
    }

    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: rgba(17, 24, 39, 0.6);
    }
}

.kppaybx {
    background: url(/assets/images/pics/kppayvector.png) no-repeat;
    background-size: 100% 100%;

}

.rpwitheth h4 {
    font-weight: 400;
    font-size: 20px;
    color: #111827;
    text-align: center;
}

.rpwitheth p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    text-align: center;
    color: rgba(17, 24, 39, 0.6);
}

.rpwitheth {
    background: #FFFFFF;
    opacity: 0.86;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    padding: 48px 47px 55px 47px;
}


/*kpgaming1*/

.kpmintbanner {
    //  background: url(/assets/images/pics/kpmintbg.png) no-repeat;
    background: #1B1B1B url(/assets/images/pics/kpmintbg1.png) no-repeat top right;
    background-size: 100% 100%;
    padding-top: 150px;

    @media(max-width:991px) {
        background: #111111;

        .mintglimg {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.kpmintbx {
    padding: 35px;
}

.kpmintbx h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
}

.kpmintbx p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
}

.kpvioletbg {
    background: #E8D6FF;
    border-radius: 4px;
}

.kpbluebg {
    background: #C2E9FF;
    border-radius: 4px;
}

.kpgreenbg {
    background: #D7FFD1;
    border-radius: 4px;
}

.kpsandalbg {
    background: #FFF0C9;
    border-radius: 4px;
}

.mintglimg img {
    margin-left: 15px;
}

/*blogbanner*/

.blogbanner {
    background: url(/assets/images/pics/blogbanner.png) no-repeat;
    background-size: 100% 100%;
    padding: 55px;
}

.kpbloghead {
    font-weight: 600;
    font-size: 40px;
    color: #FFFFFF;
}

.kpblkChain {
    background: #FFFFFF;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    min-height: 560px;
}

.kpblogbx {
    //    background: url(/assets/images/pics/kpblogimg1.png) no-repeat;
    background-size: 100% 100%;
    min-height: 296px;
    position: relative;

    img {
        &.blogImg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            object-fit: cover;
        }
    }
}

.kpblogbx1 {
    //    background: url(/assets/images/pics/kpblogimg2.png) no-repeat;
    background-size: 100% 100%;
    min-height: 296px;
    position: relative;
}

.kpblogbx2 {
    //    background: url(/assets/images/pics/kpblogimg3.png) no-repeat;
    background-size: 100% 100%;
}

.kpblogcnt {
    padding: 20px 30px;
}

.kpblogcnt h6 {
    font-weight: 275;
    font-size: 18px;
    color: #111827;
}

.kpblogcnt h3 {
    font-weight: 500;
    font-size: 20px;
    color: #111827;
    min-height: 48px;
}

.kpblogcnt p {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
    min-height: 104px;
}

.readmoretxt {
    font-weight: 500;
    font-size: 14px;
    color: #456AFF;
}

.kpblogcnt a:hover {
    text-decoration: none;
}

.horizonline {
    border-top: 0.5px solid rgba(109, 109, 109, 0.29);
}

.kpbloguser {
    background: rgba(255, 255, 255, 0.1);
    opacity: 0.86;
    backdrop-filter: blur(20px);
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px 30px;
}

.kpbloguser h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.blogpagnt h3 {
    font-weight: 400;
    font-size: 14px;
    color: #111827;
    padding: 10px 0;
}

.prevbtn {
    border: 1px solid rgba(105, 109, 118, 0.1);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    color: #696D76;
    min-height: 40px;
    margin-left: 30px;
}

.blogpagnt {
    margin-bottom: 100px;
    margin-top: 15px;
}

/*blogdetai*/

.KpBgDet {
    margin-top: 60px;
}

.KpBgDethd {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    color: #060604;
    margin-bottom: 20px;

    @media(max-width:1199px) {
        font-size: 30px;
        line-height: 35px;
    }

    @media(max-width:991px) {
        font-size: 24px;
        line-height: 30px;
    }

    @media(max-width:575px) {
        font-size: 24px;
        line-height: 30px;
    }
}


.kpbloguser1 {
    margin-top: 30px;
    margin-bottom: 50px;
}

.kpbloguser1 h5 {
    font-weight: 400;
    font-size: 12px;
    color: #111827;
}

.KpBgDetpara {
    font-weight: 400;
    font-size: 13px;
    color: rgba(17, 24, 39, 0.6);
    line-height: 26px;
}

.KpBgDet {
    p {
        font-weight: 400;
        font-size: 13px;
        color: rgba(17, 24, 39, 0.6);
        line-height: 26px;
    }

    h2 {
        font-weight: 400;
        font-size: 20px;
        color: #111827;
        margin-bottom: 0;

        @media(max-width:575px) {
            font-size: 18px;
        }
    }
}

.kplorem {
    font-weight: 400;
    font-size: 20px;
    color: #111827;
    margin-bottom: 0;

    @media(max-width:575px) {
        font-size: 18px;
    }
}

.blgnav {
    display: inline-flex;
    padding-left: 0;
    flex-wrap: wrap;
}

.blgnav li {
    list-style: none;
}

.blgnav li a {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    color: #4B4C4E;
    position: relative;
    border-right: 1px solid #4B4C4E;
    padding: 0 15px;

    &:hover {
        color: #456AFF;
    }
}

.blgnav li:first-child a {
    padding-left: 0px;
}

.blgnav li:last-child a {
    border-right: 0px;
}

// .blgnav li a:after{
//    position: absolute;
//    content: '';
//    width: 2px;
//    height: 100%;
//    background: #4B4C4E;
//    right: -8px;
// }


/*kpverification*/

.kpverifysec {
    background: url(/assets/images/pics/kpverifybg.png) no-repeat;
    background-size: 100% 100%;
    min-height: 587px;
}

.kpVfyRw {
    min-height: 587px;

}

.input-group.KpVryIngp {
    min-height: 68px;
    background: #FFFFFF;
    border-radius: 4px;
    border-top-left-radius: 4px;
    padding: 14px;
    margin-bottom: 30px;
}

.input-group.KpVryIngp .input-group-text {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.KpVryIngp .form-control,
.KpVryIngp .input-group-append span {
    background: none;
    border: none;
}

.KpVryIngp .input-group-append button.searchspan {
    background: #456AFF;
    border-radius: 4px;
    min-height: 40px;
    min-width: 120px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.KpVryIngp .input-group-append span {
    background: transparent;
}

.KpVryIn p {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
}

.KpVryIn .kpbloghead {
    margin-bottom: 35px;
    text-align: center;
}

/*kpevent*/

.kpeventbanner {
    background: url(/assets/images/pics/kpeventbg.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 50px;
}

.kpEvRw {
    min-height: 500px;
}

.kpEvRw h2 {
    font-weight: 600;
    font-size: 80px;
    line-height: 80px;
    color: #FFFFFF;
}

.kpEvRw p {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 12px;
}

.submitbtn {
    background: #456AFF;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    min-width: 120px;
    padding: 10px 13px;
    color: #FFFFFF;
}

.submitbtn:hover {
    text-decoration: none;

    color: #d7d2d2;

}

.kpeventbx {
    background: url(/assets/images/pics/kpeventimg1.png) no-repeat;
    background-size: 100% 100%;
}

.kpeventcnt h6 {
    font-weight: 600;
    font-size: 23px;
    text-align: center;
    color: #111827;
}

.kpeventcnt h3 {
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: rgba(17, 24, 39, 0.6);
    min-height: unset;
    margin-bottom: 20px;
}

.submitbtn.submitbtn1 {
    padding: 7px 22px;
}

.kpeventcnt {
    padding: 40px 30px;
}

.kpeventchain {
    min-height: unset;
}

.event {
    display: flex;
}

.eventiconmenu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.eventiconmenu li {
    list-style: none;
    padding-right: 20px;
    margin-top: 15px;
}

.kpEvBx {
    background: #FFFFFF;

    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
}

.kpEvBx h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 19px;
}

.kpEvBx h6 {
    font-weight: 400;
    font-size: 13px;
    color: rgba(17, 24, 39, 0.6);
}

.kpbksec {
    min-height: 363px;
    display: inline-grid;

    @media(max-width:1199px) {
        min-height: unset;
    }
}

.submitbtn.submitbtn2 {
    padding: 7px 40px;
}

/*kpeventdetail*/

.kpEvDet {
    background: #FFFFFF;

    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
}

.kpEvDEtail h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #111827;
    margin-bottom: 20px;
}

.kpEvDEtail h4 {
    font-weight: 400;
    font-size: 20px;
    color: #111827;
    margin-bottom: 13px;
}

.kpEvDEtail h6 {
    font-weight: 275;
    font-size: 18px;
    color: #111827;
}

.kpEvDEtail p {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #70747D;
    padding-bottom: 30px;
    margin-bottom: 0;

    @media(max-width:767px) {
        padding-bottom: 10px;
    }
}

.kpEvDEtail {
    padding: 40px;

    @media(max-width:991px) {
        padding: 15px;
    }
}

.kpEvDEtail .pkmenu2 li::marker {
    color: #717276;
}


/*kpnews*/

.kpnwsbanner {
    background: url(/assets/images/pics/kpnewsbg.png) no-repeat;
    background-size: 100% 100%;
    min-height: 200px;
}

.kpnwRw {
    min-height: 200px;
}

.kpnwChkBx {
    background: #FFFFFF;

    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
}

.kpnwctbt {
    padding: 50px 30px;

    @media(max-width:1199px) {
        padding: 30px 15px;
    }
}

.kpnwctbt h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #111827;

    @media(max-width:1199px) {
        font-size: 24px;
    }

    @media(max-width:575px) {
        font-size: 20px;
        line-height: 30px;
    }
}

.kpnwctbt p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(17, 24, 39, 0.6);
}

.kpnwctbt1 h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #111827;
    padding-bottom: 30px;
    margin-bottom: 0;

    @media(max-width:1199px) {
        font-size: 18px;
    }
}

.kpnwctbt1 {
    padding: 20px 44px 30px 20px;
}

.kpnewRtTop {
    font-weight: 275;
    font-size: 24px;
    color: #111827;
    padding-bottom: 50px;
    margin-bottom: 0;
}


/*kpnewsdetail*/
.kpnwsdetbanr {
    background: url(/assets/images/pics/kpnwdtbg.png) no-repeat;
    background-size: 100% 100%;
    min-height: 298px;

    @media(max-width:767px) {
        min-height: unset;
        padding: 15px 0px;
    }
}

.kpnwdetRw {
    min-height: 298px;
}

.kpnwdetRw h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;

    @media(max-width:1199px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media(max-width:767px) {
        font-size: 24px;
        line-height: 30px;
    }

    @media(max-width:575px) {
        font-size: 24px;
        line-height: 30px;
    }
}

.kpnwdetRw p {
    font-weight: 400;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
}

.kpnwdetRw .eventiconmenu img {
    filter: brightness(100);
}

.kpnwdetRw1 p {
    font-weight: 400;
    font-size: 13px;
    color: rgba(17, 24, 39, 0.6);
    line-height: 26px;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.kpnwdetRw1 h4 {
    font-weight: 400;
    font-size: 20px;
    color: #111827;
}

.kpshrart {
    font-size: 18px;
    line-height: 28px;
    color: #111827;
}

@media (max-width: 1399px) {
    .whyeth h3 {
        font-size: 50px;
        line-height: 55px;
    }

    .kpbuldtxt {
        font-size: 50px;
    }
}

@media (max-width: 1199px) {
    .defihd {
        text-align: center;
    }

    .kpgamehead {
        font-size: 60px;
        line-height: 63px;
        text-align: center;
    }

    .kpgamebanner {
        padding-top: 30px;

        img {
            max-height: 400px;
        }
    }

    .kpgamepara {
        text-align: center;
    }

    .whyeth h3 {
        font-size: 40px;
        line-height: 40px;
    }

    .whyeth h4 {
        font-size: 28px;
        line-height: 28px;
    }

    .ignHead {
        font-size: 30px;
        line-height: 35px;
    }
}

@media (max-width: 991px) {
    .ignImage {
        margin-top: 38px;
    }

    .ignImage1 {
        margin-bottom: 50px;
    }

    .ignImage1:after {
        right: -78px;
    }

    .defibanner {
        padding: 26px;
    }

    .kpbuldtxt {
        font-size: 40px;
        line-height: 51px;
    }

    .kpkithead {
        font-size: 37px;
    }

}

@media (max-width: 767px) {
    .kpgamehead {
        font-size: 60px;
        line-height: 63px;
    }

    .whyeth h3 {
        font-size: 40px;
        line-height: 46px;
    }

    .kpgamebanner {
        padding-top: 30px;
    }

    .ignImage1:after {
        right: -106px;
    }

    .kpkithead {
        font-size: 30px;
        line-height: 30px;
    }

    .kpbuldtxt {
        font-size: 30px;
    }

    .kpEvRw h2 {
        font-size: 40px;
        line-height: 40px;
    }

    .kpeventcnt h6 {
        font-size: 16px;
    }

    .kpeventcnt {
        padding: 40px 30px 20px;
    }
}

@media (max-width: 576px) {
    .kpgamehead {
        font-size: 36px;
        line-height: 36px;
    }

    .ignImage1:after {
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: top right;
    }

    .ignImage1 img {
        padding: 30px;
    }

    .ignImage1 {
        max-width: 100%;
    }

    .ignImage {
        max-width: 100%;
    }

    .ignImage:after {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: top left;
    }

    .ignImage img {
        padding: 30px;
        height: auto;
    }

    .ethgamecnt {
        padding: 38px;
    }

    .kpkithead {
        font-size: 24px;
        line-height: 40px;
    }

    .kpkitpara {
        line-height: 22px;
    }

    .ignHead {
        font-size: 28px;
    }

    .defibanner {
        padding: 26px;
    }

    .defihd {
        font-size: 20px;
    }

    .kppaybanner {
        padding: 30px;
    }

    .kpbuldtxt {
        font-size: 24px;
        line-height: 30px;
    }

    .KpVryIn .kpbloghead {
        font-size: 25px;
    }

    .KpVryIn .kpbloghead {
        font-size: 25px;
    }

    .kporder1 {
        order: 1;
    }

    .kporder2 {
        order: 2;
    }

    .kporder3 {
        order: 4;
    }

    .kporder4 {
        order: 3;
    }

    .kporder5 {
        order: 5;
    }

    .kporder6 {
        order: 6;
    }
}

/*Staking page css*/
.cmsBannerSec {
    width: 100%;
    min-height: 200px;
    padding: 15px;

    @media(max-width:767px) {
        min-height: unset;
    }

    &.stkBanner {
        background: url('/assets/images/stkBanner.png') no-repeat center center;
        background-size: cover;
    }

    &.wltBanner {
        background: url('/assets/images/wltBanner.png') no-repeat center center;
        background-size: cover;
    }

    h3 {
        &.cmsTitle {
            font-weight: 600;
            font-size: 40px;
            line-height: 28px;
            color: #fff;

            @media(max-width:767px) {
                font-size: 28px;
            }

            @media(max-width:575px) {
                font-size: 18px;
            }
        }
    }
}

.firstCon h3 {
    font-weight: 600;
    font-size: 60px;
    line-height: 58px;
    color: #fff;

    @media(max-width:767px) {
        font-size: 28px;
    }

    @media(max-width:575px) {
        font-size: 18px;
    }
}


.cmsCnt {
    h3 {
        &.cmsHeading {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #111827;
            margin-bottom: 15px;
        }
    }

    h5 {
        &.cmsHeading {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #111827;
            margin-bottom: 15px;
        }
    }

    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;
        color: rgba(17, 24, 39, 0.6);
        margin-bottom: 20px;
    }

    ol {
        padding-left: 18px;

        li {
            font-weight: 400;
            font-size: 13px;
            line-height: 26px;
            color: rgba(17, 24, 39, 0.6);
        }
    }

    .table-responsive {
        background: #FFFFFF;
        opacity: 0.86;
        box-shadow: 0px 6px 54px #E2F0FF;
        backdrop-filter: blur(5px);
        border-radius: 4px;
        padding: 25px;

        thead {
            th {
                border-top: 0px;
                border-color: rgba(109, 109, 109, 0.29);
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: #111827;
            }
        }

        tbody {
            td {
                font-weight: 400;
                font-size: 13px;
                line-height: 40px;
                color: rgba(17, 24, 39, 0.6);
                border-top: 0px;
                border-bottom: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }
}

.cmsHome {
    .cmsBannerSec {
        min-height: 650px;
        width: 100%;
        padding: 15px;

        @media(max-width:991px) {
            min-height: 400px;
        }

        @media(max-width:767px) {
            min-height: unset;
        }

        &.kpdaoBanner {
            background: url('/assets/images/kpdaoBanner.png') no-repeat center center;
            background-size: cover;
        }

        &.kpCommunityBanner {
            background: url('/assets/images/kpCommunityBanner.png') no-repeat center center;
            background-size: cover;
        }

        h3 {
            &.cmsTitle {
                font-weight: 600;
                font-size: 80px;
                line-height: 80px;
                color: #fff;

                @media(max-width:1199px) {
                    font-size: 60px;
                    line-height: 60px;
                }

                @media(max-width:767px) {
                    font-size: 40px;
                    line-height: 40px;
                }

                @media(max-width:575px) {
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 28px;
            color: #fff;
        }
    }
}

.whySec {
    .whyCnt {
        background: url('/assets/images/whySec.png') no-repeat center center;
        min-height: 400px;
        padding: 15px;

        @media(max-width:991px) {
            min-height: 300px;
        }

        @media(max-width:767px) {
            min-height: unset;
            padding: 50px 15px;
        }

        h5 {
            font-weight: 275;
            font-size: 30px;
            line-height: 28px;
            color: #111827;

            @media(max-width:767px) {
                font-size: 20px;
                line-height: 40px;
            }
        }

        h3 {
            font-weight: 600;
            font-size: 60px;
            line-height: 80px;
            opacity: 0.7;
            text-shadow: -1px 0 #111827, 0 1px #111827, 1px 0 #111827, 0 -1px #111827;
            color: #fff;

            @media(max-width:1199px) {
                font-size: 40px;
                line-height: 40px;
            }

            @media(max-width:767px) {
                font-size: 40px;
                line-height: 40px;
            }

            @media(max-width:575px) {
                font-size: 20px;
                line-height: 20px;
            }
        }

        h4 {
            font-weight: 275;
            font-size: 30px;
            line-height: 28px;
            color: #111827;
        }
    }
}

.container .row .midCon {
    h3 {
        font-size: 20px;
        line-height: 28px;
        color: #111827;
        margin-bottom: 15px;
    }
}

#hamburger {
    width: 35px;
    position: fixed;
    top: 70px;
    right: 0;
    z-index: 1000;
    background: linear-gradient(89.71deg, #7383c5 4.69%, #5877f7 52.77%, #456AFF 99.36%);
    border-radius: 0 0 0 15px;
    padding: 7px;
    display: none;

    @media (max-width:991px) {
        display: inline-block;
    }
}

.hamburger-line {
    stroke: #fff;
    stroke-width: 8;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transition: all .3s;

    &-1 {
        transform: translateY(-25px) rotate(0deg);

        &.active {
            transform: translateX(-30px) rotate(-45deg) translateX(30px);
            width: 40px;
        }
    }

    &-3 {
        transform: translateY(25px) rotate(0deg);

        &.active {
            transform: translateX(-30px) rotate(45deg) translateX(30px);
            width: 40px;
        }
    }
}

/*Community page styles*/
.communityCnt {
    background: #F4F4F4;
    opacity: 0.86;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    margin-bottom: 40px;

    h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #111827;
        margin: 10px 0px;
    }

    p {
        margin-bottom: 0px;
    }

    // img{
    //     &.icon{
    //         height:50px;
    //     }
    // }
}

.communityTxtCnt {
    h5 {
        font-weight: 275;
        font-size: 30px;
        line-height: 28px;
        color: #111827;

        @media(max-width:1199px) {
            font-size: 24px;
        }

        @media(max-width:575px) {
            font-size: 20px;
            line-height: 20px;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 54px;
        line-height: 60px;
        color: #111827;

        @media(max-width:1199px) {
            font-size: 40px;
            line-height: 40px;
        }

        @media(max-width:575px) {
            font-size: 30px;
            line-height: 30px;
        }
    }

    img {
        &.communityImg {
            transform: scale(2.1);
            margin-left: -20px;
            margin-top: -10px;

            @media(max-width:991px) {
                transform: none;
                margin-left: unset;
                margin-top: unset;
                max-width: 300px;
                margin: auto;
            }
        }
    }
}

.jobsHeader {
    min-height: 87px;
    padding: 15px;
    background: #FFFFFF;
    opacity: 0.86;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    width: 100%;

    ul {
        &.nav-tabs {
            border-bottom: 0px;

            li {
                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #111827;
                    border: none;
                    border-bottom: 2px solid transparent;

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #70747D;
                        margin-left: 5px;
                    }

                    &.active {
                        color: #111827;
                        border-bottom: 2px solid #456AFF;
                    }

                    &.link {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: right;
                        color: #456AFF;
                    }
                }
            }
        }
    }
}

.jobsContent {
    padding: 50px;

    @media(max-width:767px) {
        padding: 15px;
    }

    .shadowCnt {
        background: #FFFFFF;
        opacity: 0.86;
        box-shadow: 0px 6px 54px #E2F0FF;
        backdrop-filter: blur(5px);
        border-radius: 4px;
        width: 100%;
        padding: 40px;
        margin-bottom: 25px;

        @media(max-width:767px) {
            padding: 10px;
        }

        input {
            &.searchInput {
                width: 100%;
                background: #F5F7FF url(/assets/images/search-normal.svg) no-repeat left 15px center;
                padding-left: 45px;
                border-radius: 4px;
                height: 65px;
                border: none;
            }
        }

        .form-row {
            input {
                background: #F5F7FF;
                border-radius: 4px;
                height: 48px;
                border: none;
            }

            .dropdown {
                background: #F5F7FF;
                border-radius: 4px;
                height: 48px;
                display: flex;
                align-items: center;

                .dropdown-toggle {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 15px;
                    align-items: center;
                }
            }

        }

        h3 {
            &.heading {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                color: #111827;
            }
        }

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: rgba(17, 24, 39, 0.6);
        }
    }

    .btn {
        padding: 5px 10px;
        border: 1px solid rgba(105, 109, 118, 0.1);
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #696D76;
        transition: all 0.5s;

        &:hover {
            border: 1px solid rgba(105, 109, 118, 0.5);
        }

        &.dropdown-toggle {
            padding: 0px;
            background: transparent;
            border: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #70747D;

            &:focus {
                outline: none;
                box-shadow: none;
                border: none;
            }
        }
    }



    .jobCnt {
        width: 100%;

        .jobRow {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 15px;
            margin-bottom: 25px;
            border-bottom: 0.5px solid rgba(109, 109, 109, 0.29);

            img {
                &.jobIcon {
                    width: 62px;
                }
            }

            h3 {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #111827;
                margin-bottom: 5px;
            }

            p {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: rgba(17, 24, 39, 0.6);
                margin-bottom: 10px;
            }

            a {
                font-size: 14px;
                line-height: 21px;
                text-align: right;
                color: #456AFF;
                margin-left: 15px;
            }
        }
    }
}

/*kp-hackathon*/

.kpHCkbg {
    background: url(/assets/images/pics/hkban.png) no-repeat;
    background-size: 100% 100%;
    min-height: 597px;
}

.KphKRW {
    min-height: 597px;
}

.kphKhead h2 {
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #FFFFFF;
    padding-bottom: 15px;
    margin-bottom: 0;
}

.kphKhead h2 span {
    font-weight: 275;
}

.kphKhead p {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 0;
    padding-bottom: 22px;
}

.sigupbtn {
    background: #456AFF;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    min-width: 120px;
    min-height: 40px;
}

.sigupbtn:hover {
    background-color: transparent;
    border-color: #456AFF;
    color: #ffffff;
}

.kphkFrm .form-control {
    width: 383px;
    max-width: 100%;
    min-height: 40px;
}

.kpHCkbg1 {
    background: #456AFF;
    background-size: contain;
    min-height: 353px;
    background-position: right;
    position: relative;

    .container {
        z-index: 2;
        position: relative;
    }

    &:before {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 50%;
        height: 100%;
        z-index: 1;
        background: url('/assets/images/pics/hkbanimg.png') no-repeat center center;
        background-size: cover;
    }
}

.kphKhd2 {
    padding: 30px 0;
    position: relative;
}

.kphKhd2 h6 {
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;

}

.kphKhd2 h5 {
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}

.hkUsrPf {
    position: absolute;
    right: 79px;
}

.hkim2 {
    position: absolute;
    left: 0;
}

.hkim2 {
    position: absolute;
    left: 26px;
}

.hkim3 {
    position: absolute;
    left: 52px;
}

.hkim4 {
    position: absolute;
    left: 77px;
    top: 0;
}

.kphKhd3 h6 {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
}

.kphKhd3 h3 {
    font-weight: 600;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;

}

.kphkLineaft {
    position: relative;
}

.kphkLineaft:after {
    position: absolute;
    content: '';
    width: 98px;
    height: 0px;
    border: 1px solid rgba(255, 255, 255, 0.37);
    transform: rotate(90deg);
    right: -75px;
    top: 35px;
}

.kphKhd3 {
    margin-top: 80px;
}

.kpHckWave {
    margin-top: 100px;
}

.kpHckWave h3 {
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    color: #111827;
}

.kpHckWave h3 span {
    font-weight: 275;

}

.hkimage {
    background: #FFFFFF;
    opacity: 0.86;
    box-shadow: 0px 6px 54px #E2F0FF;
    backdrop-filter: blur(5px);
    border-radius: 4px;
    width: 174px;
    height: 174px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kpHckWaveimg {
    background: url(/assets/images/pics/hkvector.png) no-repeat;
    background-size: contain;
    min-height: 485px;
    background-position: top 10px center;
}

.HkSpncrs {
    background: #FBFBFB;
    padding: 52px;
}

.prevhd {
    font-weight: 275;
    font-size: 30px;
    color: #111827;
    margin-bottom: 0;
    padding-bottom: 5px;
}

.prevbld {
    font-weight: 600;
    font-size: 60px;
    color: #111827;
    margin-bottom: 0;
    margin-top: -14px;
}

.previmg {
    position: relative;
}

.prevcntn {
    position: absolute;
    top: 65px;
    left: 20px;
}

.prevcntn h3 {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

.prevcntn p {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);
}

.prevpar {
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    color: rgba(74, 74, 74, 0.9);
}

@media (max-width: 1399px) {
    .whyeth h3 {
        font-size: 50px;
        line-height: 55px;
    }

    .kpbuldtxt {
        font-size: 50px;
    }
}

@media (max-width: 1199px) {
    .defihd {
        text-align: center;
    }

    .kpgamehead {
        font-size: 60px;
        line-height: 63px;
        text-align: center;
    }

    .kpgamebanner {
        padding-top: 30px;

        img {
            max-height: 400px;
        }
    }

    .kpgamepara {
        text-align: center;
    }

    .whyeth h3 {
        font-size: 40px;
        line-height: 40px;
    }

    .whyeth h4 {
        font-size: 28px;
        line-height: 28px;
    }

    .ignHead {
        font-size: 30px;
        line-height: 35px;
    }
}

@media (max-width: 991px) {
    .ignImage {
        margin-top: 38px;
    }

    .ignImage1 {
        margin-bottom: 50px;
    }

    .ignImage1:after {
        right: -78px;
    }

    .defibanner {
        padding: 26px;
    }

    .kpbuldtxt {
        font-size: 40px;
        line-height: 51px;
    }

    .kpkithead {
        font-size: 37px;
    }

    .odr1 {
        order: 1;
    }

    .odr2 {
        display: flex;
        order: 2;
    }

    .odr2 img {
        padding: 15px 14px;
    }

    .prevcntn h3 {
        font-size: 13px;
        line-height: 15px;
    }

    .prevcntn p {
        font-size: 12px;
    }

}

@media (max-width: 767px) {
    .kpgamehead {
        font-size: 60px;
        line-height: 63px;
    }

    .whyeth h3 {
        font-size: 40px;
        line-height: 46px;
    }

    .kpgamebanner {
        padding-top: 30px;
    }

    .ignImage1:after {
        right: -106px;
    }

    .kpkithead {
        font-size: 30px;
        line-height: 30px;
    }

    .kpbuldtxt {
        font-size: 30px;
    }

    .kpEvRw h2 {
        font-size: 40px;
        line-height: 40px;
    }

    .kpeventcnt h6 {
        font-size: 16px;
    }

    .kpeventcnt {
        padding: 40px 30px 20px;
    }

    .kpEvRw h2 {
        font-size: 40px;
        line-height: 40px;
    }

    .kpeventcnt h6 {
        font-size: 16px;
    }

    .kpeventcnt {
        padding: 40px 30px 20px;
    }

    .kphKhead h2 {
        font-size: 33px;
        line-height: 49px;
        margin-top: 50px;
    }

    .kpHCkbg1 {
        background: url(/assets/images/pics/hkbanimg.png) no-repeat;
    }

    .kpHckWave {
        margin-top: 50px;
    }

    .kpHckWave h3 {
        font-size: 40px;
        line-height: 49px;
    }

    .prevbld {
        font-size: 39px;
    }

    .hksvgcls {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .kpgamehead {
        font-size: 36px;
        line-height: 36px;
    }

    .ignImage1:after {
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: top right;
    }

    .ignImage1 img {
        padding: 30px;
    }

    .ignImage1 {
        max-width: 100%;
    }

    .ignImage {
        max-width: 100%;
    }

    .ignImage:after {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: top left;
    }

    .ignImage img {
        padding: 30px;
        height: auto;
    }

    .ethgamecnt {
        padding: 38px;
    }

    .kpkithead {
        font-size: 24px;
        line-height: 40px;
    }

    .kpkitpara {
        line-height: 22px;
    }

    .ignHead {
        font-size: 28px;
    }

    .defibanner {
        padding: 26px;
    }

    .defihd {
        font-size: 20px;
    }

    .kppaybanner {
        padding: 30px;
    }

    .kpbuldtxt {
        font-size: 24px;
        line-height: 30px;
    }

    .KpVryIn .kpbloghead {
        font-size: 25px;
    }

    .KpVryIn .kpbloghead {
        font-size: 25px;
    }

    .kporder1 {
        order: 1;
    }

    .kporder2 {
        order: 2;
    }

    .kporder3 {
        order: 4;
    }

    .kporder4 {
        order: 3;
    }

    .kporder5 {
        order: 5;
    }

    .kporder6 {
        order: 6;
    }
}

.kpparkchain {
    background: url('/assets/images/kpparkchainBanner.png') no-repeat center center;
    background-size: cover;
    min-height: 710px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.kpGameSubHead {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    color: #FFFFFF;
}

.kpgameheadLg {
    font-weight: 200;
    font-size: 30px;
    line-height: 28px;
    color: #FFFFFF;
}

.shadowCnt {
    border-radius: 4px;
    opacity: 0.8600000143051147;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);
    width: 100%;
}

.wtParkchainCnt {
    .rpwitheth {
        padding: 20px;
        min-height: 380px;

        @media(max-width:1199px) {
            min-height: 350px;
        }
    }
}

.kpkEthSec {
    .ethgamecnt {
        padding: 35px;
        background: rgba(255, 255, 255, 0.2);
    }

    .communityTxtCnt {
        h3 {
            font-size: 40px;

            @media(max-width:1199px) {
                font-size: 36px;
            }

            @media(max-width:991px) {
                font-size: 30px;
            }

            @media(max-width:575px) {
                font-size: 24px;
            }
        }

        h5 {
            font-size: 24px;
            font-weight: 600;
            color: #111827;

            @media(max-width:1199px) {
                font-size: 22px;
            }

            @media(max-width:991px) {
                font-size: 20px;
            }

            @media(max-width:575px) {
                font-size: 18px;
            }
        }
    }
}

.kpkpurCnt {
    background: url("/assets/images/kpparkchain/Group 1261153077.png") no-repeat center center;
    background-size: cover;
    margin-bottom: 15px;
}

.kpkorCnt {
    background: url("/assets/images/kpparkchain/bluebh.png") no-repeat center center;
    background-size: cover;
    margin-bottom: 15px;
}

.kpkbluCnt {
    background: url("/assets/images/kpparkchain/whoruns-bg.png") no-repeat center center;
    background-size: cover;
    margin-bottom: 15px;
}

.kpkgrnCnt {
    background: url("/assets/images/kpparkchain/purple.png") no-repeat center center;
    background-size: cover;
    margin-bottom: 15px;
}

.kpkTextCnt {
    padding: 40px;

    @media(max-width:575px) {
        padding: 20px;
    }

    h3 {
        &.heading {
            color: #111827;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
        }
    }

    p {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-weight: 400;
        line-height: 26px;
    }
}

.kpkTabCnt {
    .shadowCnt {
        display: flex;
        position: relative;
        min-height: 215px;

        @media(max-width:575px) {
            flex-direction: column;
        }

        .nav-tabs {
            width: 70px;
            height: 100%;
            border-bottom: 0px;
            position: absolute;
            left: 0px;
            top: 0px;

            @media(max-width:575px) {
                position: relative;
                width: 100%;
            }

            li {
                width: 70px;
                height: 70px;

                &:last-child {
                    margin-top: auto;

                    @media(max-width:575px) {
                        margin-top: 0px;
                        margin-left: auto;
                    }
                }

                a {
                    width: 100%;
                    height: 100%;
                    color: rgba(30, 112, 254, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 50px;
                    border-radius: 0px;
                    border: none;

                    @media(max-width:575px) {
                        font-size: 30px;
                    }

                    &.active {
                        background: rgba(30, 112, 254, 0.1);
                        color: rgba(30, 112, 254, 1);
                    }

                    &:hover {
                        color: rgba(30, 112, 254, 1);
                        cursor: pointer;
                    }
                }
            }
        }

        .tab-content {
            padding: 30px 15px;
            padding-left: 100px;

            @media(max-width:575px) {
                padding: 15px;
                padding-left: 15px;
            }

            h3 {
                &.heading {
                    color: #111827;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                }
            }

            p {
                color: rgba(17, 24, 39, 0.60);
                font-size: 13px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
}

/*E O Parkchain page style*/
/*KPK Home page style*/
.kpparkchain {
    background: url('/assets/images/kpkDetailBanner.png') no-repeat center center;
    background-size: cover;
    min-height: 710px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        &.kpkpkBannerImg {
            mix-blend-mode: screen;
        }
    }
}

.getKPK {
    .communityTxtCnt {
        .getKPKImgCnt {
            background: url('/assets/images/getKPKBg.png') no-repeat center right;

            img.communityImg {
                transform: scale(1.4);
            }
        }
    }
}

.wtParkchainCnt {
    .kpkBx {
        .rpwitheth {
            min-height: 360px;
            padding: 50px 20px;
        }
    }
}

.rrski_left {
    p {
        text-align: start;
    }
}

/*E O KPK Home page style*/


.ims_wd_100 {
    width: 100%;
    height: 278px;
    object-fit: cover;
}

.ims_wdin_100 {
    width: 100%;
    height: 317px;
    object-fit: cover;
}




/** Sethu Css **/

.pksidebr {
    overflow-y: auto;
}

.indFtr {
    // background-color: #fff;
    position: relative;
    z-index: 99;
}

@media only screen and (min-width: 992px) {

    .sidenav {
        ul {
            padding-bottom: 25em;
        }
    }

}

@media only screen and (max-width: 480px) {

    .hdNavMenu {
        width: 220px;
    }

    .pksidebr {
        width: 230px;
    }

}

.parkhome-pagecontent {
    .bnrLks {
        a {
            &:hover {
                color: #456AFF;
            }
        }
    }
}

/*Find Wallet Styles*/
.findWalletSec {
    .tabRowCnt {
        border-radius: 4px;
        opacity: 0.8600000143051147;
        background: #FFF;
        box-shadow: 0px 6px 54px 0px #E2F0FF;
        backdrop-filter: blur(5px);
        height: 108px;
    }

    .filterTxt {
        color: #5C606B;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    .findWalletTableCnt {
        table {
            thead {
                th {
                    border-bottom: 0px;

                    select {
                        border: none;
                        color: #5C606B;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                        background: url('/assets/images/pics/downimg.png') no-repeat center right;
                        padding-right: 25px;
                        appearance: none;

                        &:focus {
                            border: none;
                            outline: none;
                        }

                        option {
                            color: #5C606B;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        border-top: 0px;
                        border-bottom: 1px solid rgba(109, 109, 109, 0.29);

                        h3 {
                            &.heading {
                                color: #111827;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                margin-bottom: 0px;
                            }

                            &.colHeading {
                                color: #456AFF;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 10px;
                            }
                        }

                        p {
                            color: rgba(17, 24, 39, 0.60);
                            font-size: 13px;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 10px;
                        }

                        ul {
                            padding-left: 0px;

                            li {
                                color: rgba(17, 24, 39, 0.60);
                                font-size: 13px;
                                font-weight: 400;
                                line-height: normal;
                                list-style-type: none;
                                margin-bottom: 10px;

                                &.strike {
                                    img {
                                        opacity: 0.5;

                                        &.exclaim {
                                            opacity: 1;
                                        }
                                    }

                                    span {
                                        text-decoration-line: strikethrough;
                                        text-decoration: line-through;
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(even) {
                        td {
                            border-bottom: 0px;
                        }
                    }
                }

            }
        }
    }

    .findWalletTabCnt {
        &.nav-tabs {
            border-bottom: 0px;

            li {
                margin-right: 10px;

                a {
                    &.nav-link {
                        color: #111827;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1;
                        padding: 10px 0px;
                        border: none;
                        border-bottom: 2px solid transparent;
                    }

                    &.active {
                        border: none;
                        color: #7291FF;
                        border-bottom: 2px solid #7291FF;
                        background: transparent;
                    }

                    &:hover {
                        border-color: transparent;
                        cursor: pointer;
                        border-bottom: 2px solid #7291FF;
                    }
                }
            }
        }
    }
}

/*E O Find Wallet Styles*/
.btn-primary {
    background-color: #456AFF;
    border-color: #007bff;
}

/*--- Hackathon List view page Start ---*/

.hckLsPg {
    min-height: calc(100vh - 76px - 384px);
}

/* -- Banner Start -- */
.hckLsBnr {
    background-color: #060604;
    margin-bottom: 1.5rem;
}

.hckLsBnrR {
    align-items: center;
    justify-content: space-between;
}

.hckLsBnrTx h1 {
    margin: 0px;
    font-size: 40px;
    font-weight: 600;
    color: #ffffff;
}

.hckLsBnrIg {
    overflow: hidden;
}

.hckLsBnrIg img {
    margin: -10.5px 0px -26.5px;
}

@media only screen and (max-width: 991px) {
    .hckLsBnrTx {
        text-align: center;
        padding: 1.5rem 0px;
    }

    .hckLsBnrIg {
        text-align: right;
    }

    .hckLsBnrIg img {
        margin: 0px 0px -26.5px;
    }
}

@media only screen and (max-width: 575px) {
    .hckLsBnrTx h1 {
        font-size: 36px;
    }

    .hckLsBnrIg img {
        margin: 0px -20% -26.5px;
    }
}

/*-- Banner End --*/


/*-- Tab content Grid Start --*/
.hckLsEvesTab {
    margin-bottom: 1rem;
}

.hckLsEvesTab .nav-tabs {
    border: 0px;
    margin: 0px -4px;
}

.hckLsEvesTab .nav-tabs .nav-link {
    min-width: 139px;
    text-align: center;
    border: 1px dashed #111827;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 9.5px 6px;
}

.hckLsEvesTab .nav-tabs .nav-item {
    margin: 0px;
    padding: 0px 4px 0.5rem;
}

.hckLsEvesTab .nav-tabs .nav-link.active {
    background-color: #456AFF;
    border-color: #456AFF;
    color: #ffffff;
}


.hckLsEvesTabCon {
    margin-bottom: 0.5rem;
}


.hckEveCon {
    border-radius: 4px;
    position: relative;
    background-color: #ffffff;
    height: 100%;
}

.hckEveCon::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0.8600000143051147;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);
}

.hckEveCon>* {
    position: relative;

}

.hckEveConIg img {
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    max-height: 183px;
}

.hckEveConTx {
    padding: 22px 18px 22px 22px;
}

.hckEveConTx h2 {
    font-weight: 500;
    line-height: 26px;
    font-size: 20px;
    padding-bottom: 8px;
}

.hckEveEdDt h6 {
    letter-spacing: 2.924px;
    text-transform: uppercase;
    color: #494958;
    font-size: 14.618px;
    line-height: normal;
    margin-bottom: 6px;
}

.hckEveEdDtR {
    display: flex;
    padding-bottom: 10px;
}

.hckEveEdDtC {
    text-align: center;
    position: relative;
    margin-right: 18px;
    min-width: 56px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 62px;
}

.hckEveEdDtC::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0.6000000238418579;
    background: #494958;
}

.hckEveDtN {
    position: relative;
    font-size: 27.906px;
    color: #ffffff;
    letter-spacing: 0.558px;
    font-weight: 600;
    line-height: 27px;
}

.hckEveDtT {
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    font-size: 10px;
}

.hckEveConCnSt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 4px;
}

.hckEveConCnSt>* {
    margin-top: 12px;
}

.hckEveCnt {
    display: flex;
    align-items: center;
    background-color: rgba(73, 73, 88, 0.6);
    border-radius: 6px;
    margin-right: 10px;
}

.hckEveCntTx {
    padding: 8.5px 10px;
    font-size: 14px;
    color: #ffffff;
}

.hckEveCntNb {
    border-radius: 0px 6px 6px 0px;
    background-color: #111827;
    display: inline-flex;
    align-items: center;
    padding: 8.5px 16px 8.5px 9px;
    font-size: 14px;
    color: #ffffff;
}

.hckEveCntNb svg {
    margin-right: 6px;
}

.btn-11738 {
    min-width: 117px;
    font-size: 16px;
    font-weight: 500;
}

.btn-bluFl.v2:hover {
    color: #456aff;
}

.hckEveR {
    margin-left: -10px;
    margin-right: -10px;
}

.hckEveC {
    padding: 0px 10px 1.25rem;
}

@media only screen and (max-width: 991px) {
    .hckEveCnt {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
    }

    .hckEveCntTx {
        flex-grow: 1;
        text-align: center;
    }

    .hckEveStr {
        flex-grow: 1;
    }

    .hckEveStr .btn {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .hckEveConTx h2 {
        font-size: 18px;
        padding-bottom: 4px;
    }

    .hckEveDtN {
        font-size: 23px;
    }

    .hckLsEvesTab .nav-tabs {
        justify-content: center;
    }
}

@media only screen and (max-width: 349px) {
    .hckEveEdDtC {
        margin-right: 8px;
    }
}

/*-- Tab content Grid End --*/



/*-- Tab content List Start --*/
.hckEveLs {
    position: relative;
    display: flex;
}

.hckEveLs>* {
    position: relative;
}

.hckEveLs::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: 0.8600000143051147;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);
}

.hckEveLsIg img {
    border-radius: 6.419px 6.419px 6.42px 6.42px;
    width: 179px;
    min-width: 179px;
    height: 100%;
    object-fit: cover;
}

.hckEveLsIg::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: rgba(69, 106, 255, 0.60);
}

.hckEveLsIg span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 500;
    line-height: 25.674px;
    color: #ffffff;
}

.hckEveLsTx {
    padding: 16px 20px;
    flex-grow: 1;
}

.hckEveLsTx h6 {
    font-size: 18px;
    font-weight: 500;
    color: #111827;
    padding-bottom: 2px;
}

.hckEveLsDt {
    font-size: 14px;
    color: #000000;
    margin-bottom: 12px;
}

.hckEveLsDt svg {
    margin-right: 6px;
}

.hckEveLsPtPz {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.hckEveLsPz {
    white-space: nowrap;
}

.hckEveLsPzL {
    font-size: 14px;
    color: #111827;
    line-height: 15px;
}

.hckEveLsIg {
    border-radius: 6.419px 6.419px 6.42px 6.42px;
}

.hckEveLsPzV {
    color: #111827;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
}

@media only screen and (max-width: 1199px) {
    .hckEveLsPtPz {
        flex-direction: column-reverse;
        align-items: flex-start;

        .hckEveCnt {
            margin-top: 10px;

            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .hckEveLs {
        flex-wrap: wrap;
    }

    .hckEveLsIg {
        flex: 0 0 100%;
    }

    .hckEveLsIg img {
        width: 100%;
    }

    .hckEveLsTx {
        flex: 0 0 100%;
    }
}

/*-- Tab content List End --*/


/*--- Hackathon List view page End ---*/





/*--- Hackathon Details page Start ---*/

// Bnr Start
.hckDtBnr {
    padding: 11px 0px 1.125rem;
}

.hckDtBnrCr {
    border-radius: 50%;
    box-shadow: 0px 1px 21px rgba(17, 24, 39, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    position: relative;
    --hckDtBnrCr: clamp(30px, 9vw, 100px);
    width: var(--hckDtBnrCr);
    height: var(--hckDtBnrCr);
    padding: 2px;
    margin-top: calc((var(--hckDtBnrCr)/2) * -1);
    margin-left: calc((var(--hckDtBnrCr)/2) * 1);
}

.hckDtBnrCr img {
    max-width: 60%;
}

// Bnr End


// Dt Bnr Start
.hckDtFlHd h1 span {
    border-radius: 4px;
    background-color: #FC9F07;
    min-width: 64px;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 1.5px 4px;
    color: #ffffff;
    margin-bottom: 2px;
    margin-right: 4px;
}

.hckDtFlHd h1 {
    font-size: 26px;
    color: #111827;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 8px;
}



.hckDtFlBdR {
    justify-content: space-between;
    align-items: center;
}

.hckDtFlEvCon {
    display: flex;
}

.hckDtFlEvCnLt {
    margin-right: 5px;
}

.hckDtFlLb {
    font-size: 14px;
    color: #000000;
    line-height: normal;
    padding: 4px 0px 2px;
    margin-bottom: 2px;
}

.hckDtFlVl {
    font-size: 15px;
    font-weight: 600;
    color: #111827;
}

.hckDtFlBdC {
    padding-bottom: 1rem;
}

.hckDtFl {
    padding-bottom: 1rem;
    margin-bottom: 6px;

    @media only screen and (max-width: 991px) {
        padding-bottom: 0rem;
    }
}

.hckDtFlBd {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #E6E6E6;
}

.btn-13738 {
    min-width: 137px;
}

@media only screen and (max-width: 575px) {
    .hckDtFlHd h1 {
        font-size: 20px;
    }

    .hckDtFlVl {
        font-size: 14px;
    }

    .btn-13738 {
        font-size: 14px;
        min-width: 117px;
    }
}

// Dt Bnr End


// Dt Tab Start
.hckDtTabR {
    margin-right: -10px;
    margin-left: -10px;
}

.hckDtTabC {
    padding-left: 10px;
    padding-right: 10px;
}

.hckDtTab {
    width: 272px;
    background-color: #F6F6F6;
    padding: 1.5rem 1rem;

    @media only screen and (max-width: 991px) {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100%;
        z-index: 9999;
        overflow: auto;

        &:not(.show) {
            transform: translateX(-100%);
        }

        transition: 0.3s transform;
    }
}

.hckDtTab .nav-tabs {
    border: 0px;
    flex-direction: column;
}

.hckDtTab .nav-item {
    margin: 0px;
}

.hckDtTab .nav-link {
    border: 0px;
    font-size: 14px;
    color: #484848;
    font-weight: 500;
    cursor: pointer;
    margin: 4px 0px;
}

.hckDtTab .nav-link.active,
.hckDtTab .nav-item.show:not(.dropdown) .nav-link {
    background-color: transparent;
    color: #456AFF;
}

.hckDtTab .nav-tabs .dropdown-menu {
    margin-top: 0px;
    position: relative;
    background-color: transparent;
    border: 0px;
    padding: 0px 18px;
}

.hckDtTab .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hckDtTab .dropdown-toggle::after {
    border: 0px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.999939L5 4.99994L9 0.999939' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 10px;
    height: 6px;
}

.hckDtTab .dropdown-toggle.active::after {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.999939L5 4.99994L9 0.999939' stroke='%23456AFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.hckDtTab .nav-tabs .nav-item ul {
    padding: 1px 18px;
}

// Dt Tab End


// Dt Tab Con Start
.hckDtTabCnt {
    margin-bottom: 3rem;
}

.hckDtCms h6 {
    font-weight: 600;
    color: #111827;
    line-height: normal;
    padding-bottom: 4px;
    font-size: 18px;
}

.hckDtCms {
    color: #484848;

    p {
        font-weight: 400;
        line-height: 26px;
        color: #484848;
        font-size: 14px;
        margin: 0px;

        a {
            color: #484848;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}


.hckDtCms {
    ul {
        margin: 0px;
        padding-left: 20px;

        li {
            line-height: 26px;
            font-size: 14px;

            ul {
                list-style: disc;
            }
        }

        +br {
            content: '';
            height: 18px;
            display: block;
        }
    }

    ol {
        margin: 0px;
        padding-left: 18px;

        li {
            line-height: 26px;
            font-size: 14px;
            padding-left: 4px;

            ul {
                list-style: disc;
            }
        }
    }
}

// Dt Tab Con End


// Dt Tab Tg Strat
#hckDtTg {
    width: 30px;
    background-color: #456aff;
    height: 30px;
}

.hckDtTabCntTg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: sticky;
    top: 76px;
    background-color: #ffffff;
    z-index: 9;
    padding: 1rem 0px;

    @media only screen and (min-width: 992px) {
        display: none;
    }
}

.hckDtTg {
    width: 30px;
    height: 30px;
    background-color: #456aff;
}


.hckDtTabCls {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 9;
}

.hckDtTabCls svg {
    width: 30px;
    height: 30px;
}

// Dt Tab Tg End


// Dt Date & Time Start
.dbTbl {
    background-color: #F6F6F6;
    overflow-x: auto;
    white-space: nowrap;
}

.dbTbl thead th {
    font-size: 14px;
    color: #111827;
    font-weight: 600;
    border-top: 0px;
    border-bottom: 1px solid #E9E9E9;
    padding: 13.25px 18px 13.25px 18px;
}

.dbTbl tbody td {
    font-size: 13px;
    color: #111827;
    padding: 13.25px 18px 13.25px 18px;
    border: 0px;
}

.dbTbl th:not(:last-child),
.dbTbl td:not(:last-child) {
    border-right: 1px solid #E9E9E9;
}

.dbTbl table {
    margin: 0px;
}

// Dt Date & Time End



// Dt Prizes Start
.hckDtPz {
    border-radius: 3.389px;
    border: 0.565px solid #EAF0F5;
    background-color: #ffffff;
    margin-bottom: 8px;
    padding: 9px 12px;
}

.hckDtPzInr {
    border-radius: 3.389px;
    background-color: #F4F5FA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 0px 8px;
}

.hckDtPzInrLt {
    display: inline-flex;
    align-items: center;
}

.hckDtPzIc {
    margin-right: 12px;
}

.hckDtPzCt {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
}

.hckDtPzInrRt {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
}

.hckDtPzLb {
    font-size: 14px;
    color: #111827;
    margin-right: 12px;
    line-height: 17px;
}

.hckDtPzAmt {
    border-radius: 5.57px;
    background-color: #06F;
    min-width: 149px;
    text-align: center;
    font-size: 18.133px;
    color: #ffffff;
    font-weight: 600;
    padding: 3.5px 0px;
}

@media only screen and (max-width: 575px) {
    .hckDtPzInr {
        justify-content: space-around;
        flex-direction: column;
    }

    .hckDtPzInrRt {
        flex-direction: column;
    }

    .hckDtPzLb {
        margin: 10px 0px 5px;
    }

    .hckDtPzAmt {
        margin-bottom: 16px;
    }
}

// Dt Prizes End


// Dt Theme Start
.hckDtThmCd {
    position: relative;
    text-align: center;
    padding: 26px 45px;
    height: 100%;

    @media only screen and (max-width: 575px) {
        padding: 26px;
    }
}

.hckDtThmCd::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 3.345px;
    opacity: 0.8600000143051147;
    background-color: #ffffff;
    box-shadow: 0px 5.017646789550781px 45.1588249206543px 0px #E2F0FF;
    backdrop-filter: blur(4.18137264251709px);
    pointer-events: none;
}

.hckDtThmCd>* {
    position: relative;
}

.hckDtThmCdIc {
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}

.hckDtThmCdTl {
    color: #111827;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
}

.hckDtThmCdTx {
    font-weight: 400;
    line-height: 21.743px;
    font-size: 13px;
    color: rgba(17, 24, 39, 0.60);
}

.hckDtThmR {
    display: grid;
    grid-template-columns: auto auto;
    gap: 36px 74px;
    padding-bottom: 20px;

    @media only screen and (max-width: 1199px) {
        gap: 36px 36px;
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: auto;
    }

    @media only screen and (max-width: 575px) {
        gap: 26px;
    }
}

// Dt Theme End


/*--- Hackathon Details page End ---*/



/*--- Developer Document page Start ---*/
.devdocBnr {
    background-image: url('../assets/images/devdoc/devdocBg.jpg');
    background-size: cover;
    position: relative;
    padding: clamp(1rem, 4vw, 38px) 0px;

    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, #0D080F 33.85%, rgba(17, 24, 39, 0.00) 100%);
    }
}

.devdocBnrR {
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
}

.devdocBnrTx {
    margin-bottom: 0px;
    color: #ffffff;

    h2 {
        line-height: 1;

        @media only screen and (max-width: 479px) {
            line-height: 1.5;
        }
    }
}

.devdocBnrC {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @media only screen and (max-width: 991px) {
        &.rt {
            padding-top: 0px;
        }
    }
}

.btn-12040.f14 {
    font-size: 14px;
}



.devdocMtd {
    padding: clamp(2rem, 9vw, 100px) 0px;
    position: relative;
}


.devdocMtdC {
    padding-top: 10px;
    padding-bottom: 10px;
}

.devdocMtd .kyFtrIc {
    @media only screen and (min-width: 576px) {
        min-height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.devdocMtdOl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    pointer-events: none;
    width: 100%;
    text-align: center;
}

/*--- Developer Document page End ---*/

/** 14-07-2023 **/

.kpparkchain-new {
    background-image: url('../assets/images/kpparkchain/kppark-bnr.png');
}

.rpwitheth {
    border-radius: 4px;
    // opacity: 0.8600000143051147;
    opacity: unset;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(3px);
}


.developerdoc-ul {
    margin: 1.5em 0;
    padding: 0 0 0 5em;
    list-style-type: disc;

    li {
        text-align: left;
    }

}



//** KPKDigital Page Css START **/ 

.kpkdigital-pagecont {

    .kpkdigital-sec {
        background-image: url('../assets/images/kpkdigital/kpdigital-bnr.png');

        .bnrTx {
            padding: 30px 0 0 0;
        }

    }

    .kpkdigital-bnrlight {
        img {
            mix-blend-mode: lighten;
        }
    }

    .rpbox-img {
        margin: 10px 0 0 0;
    }

    .kpk-fuelscnt {
        margin: 70px 0 0 0;
    }

    .kpkdigitalbox-sec {
        padding: 30px 0 0 0;
    }

    .rpbox-img {
        img {
            transition: .5s all;
        }
    }

    .wtParkchainCnt .rpwitheth {
        opacity: unset;
        padding: 30px 24px;
        min-height: 450px;
        transition: .5s all;

        &:hover {
            margin: -10px 0 0 0;

        }

    }

    .wtParkchainCnt .rpwitheth h4 {
        line-height: 28px;
    }

    .wtParkchainCnt .rpwitheth p {
        margin: 15px 0 0 0;
    }

    .kpkfuelssys-sec {
        h4 {
            color: #111827;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            margin: 0 0 20px 0;
        }

        p {
            color: rgba(17, 24, 39, 0.60);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin: 0 0 10px 0;
        }
    }

    .kpkEthSec {
        margin: 50px 0 0 0;

        .ethgamekit {
            background-image: url('../assets/images/kpkdigital/getbnr123.png');
        }


        .ethgamecnt {
            opacity: unset;
            box-shadow: unset;

            p {
                color: rgba(17, 24, 39, 0.60);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }

    .valuekpk-sec {
        margin: 50px 0 0 0;
        padding: 0 0 50px 0;
    }

    .tokenstand-sec {
        .rpwitheth {
            min-height: 475px;
        }
    }


    @media only screen and (max-width: 767px) {

        .ethgamekit,
        .ethgamecnt {
            padding: 0;
        }

    }



}

/** KPKDigital Page Css END **/




/** Exchange Page Css START **/

.kpkexchange-pagecont {
    padding: 0 0 30px 0;

    .kpkexchange-bnrsec {
        background-image: url('../assets/images/kpkdigital/exchange-bnr.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        min-height: 600px;

        padding: 50px 0 30px 0;

        .kpkexchange-leftcnt {
            h2 {
                color: #FFF;
                font-size: 65px;
                font-style: normal;
                font-weight: 600;
                line-height: 75px;
                margin: 0 0 40px 0;

                span {
                    font-weight: 400;
                }

            }

            p {
                color: #FFF;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;

            }

        }

    }

    .exchangeleftright-sec {
        padding: 60px 0 0 0;

        h2 {
            color: #111827;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            /* 170% */
            margin: 0 0 30px 0;
        }

        p {
            color: rgba(17, 24, 39, 0.60);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }

        .exchange-inrbgcnt {
            margin: 30px 0 0 0;
            padding: 20px;
            border-radius: 4px;
            // opacity: 0.8600000143051147;
            background: #FFF;
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            backdrop-filter: blur(5px);
        }

    }


    @media only screen and (max-width: 767px) {

        .kpkexchange-leftcnt {
            h2 {
                font-size: 40px !important;
                line-height: 50px !important;
            }

        }

        .exchangeleftright-sec {
            h2 {
                font-size: 30px !important;
                line-height: 40px !important;
            }
        }

        .order-img {
            order: 2;
            margin: 30px 0 0 0;
        }
    }

}

/** Exchange Page Css END **/


/** ParkWallet Page Css START **/

.kpndwaltl {
    .crpsec {
        padding-top: 40px;

        h2 {
            color: #111827;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }

        p {
            color: rgba(17, 24, 39, 0.60);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }

    .pHdon {
        padding-top: 20px;
        padding-bottom: 20px;

        h2 {
            color: #111827;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 15px;
        }

        p {
            color: rgba(17, 24, 39, 0.60);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 16px;
        }

        .crd_pho {
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.86);
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            backdrop-filter: blur(5px);
            padding: 20px 20px;
            min-height: 100%;

            h1 {
                color: #111827;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }

            p {
                color: rgba(17, 24, 39, 0.60);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                text-align: left;
            }

            .inimg {
                text-align: center;
            }
        }
    }

    .uplscd {
        padding-bottom: 20px;

        h2 {
            color: #111827;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 20px;
            max-width: 845px;
            margin: auto;

        }

        h1 {
            color: #111827;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 15px;
        }

        p {
            color: rgba(17, 24, 39, 0.60);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 23px;
        }

    }

    .Mtstl {
        text-align: center;
        padding-bottom: 20px;

        h1 {
            color: #111827;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
        }
    }

    .DcAtl {
        padding-top: 60px;
        padding-bottom: 30px;

        h1 {
            color: #111827;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;


        }

        h2 {
            color: #111827;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
        }

        h3 {
            color: #0D011E;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0px;
        }
    }

    .EpE {
        .cdsd {
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            backdrop-filter: blur(5px);
            padding: 20px 20px;

            h2 {
                color: #111827;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }

            p {
                color: rgba(17, 24, 39, 0.60);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0px;
            }

        }

    }

    .byCEp {
        padding-top: 20px;
        padding-bottom: 60px;

        h1 {
            color: #111827;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
        }

        .MrC {
            margin-top: -40px;

            h2 {
                color: #456AFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
            }

            @media (max-width:1199px) {
                margin-top: 20px;
            }
        }
    }

}


.OwnCreatMrkplMain {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 23px;
    grid-row-gap: 10px;
    background-blend-mode: multiply;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-template-columns: repeat(5, 100px);
        justify-content: center;
    }

    @media (max-width:767px) {
        display: block;
    }
}

@media (max-width:767px) {
    .OwnNftItm {
        margin: 5px 0;
    }
}

.OwnNftItm1 {
    grid-area: 1 / 1 / 2 / 2;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 1/1/2/3;
    }
}

.OwnNftItm2 {
    grid-area: 1 / 6 / 2 / 7;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 1/6/2/4;
    }
}

.OwnNftItm3 {
    grid-area: 2 / 2 / 3 / 3;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 2/1/2/3;
    }
}

.OwnNftItm4 {
    grid-area: 2 / 5 / 3 / 6;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 2/4/2/6;
    }
}

.OwnNftItm5 {
    grid-area: 3 / 3 / 4 / 4;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 3/1/4/3;
    }
}

.OwnNftItm6 {
    grid-area: 3 / 4 / 4 / 5;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 3/4/4/6;
    }
}

.OwnNftItm7 {
    grid-area: 4 / 2 / 5 / 3;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 4/1/5/3;
    }
}

.OwnNftItm8 {
    grid-area: 4 / 5 / 5 / 6;

    @media (min-width: 768px) and (max-width: 1199px) {
        grid-area: 4/4/4/6;
    }
}

.OwnNftCnt {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);
    padding: 10px;

    h4 {
        color: #0D011E;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        box-shadow: 0 10px 20px #1db5ff59;
        margin-top: -5px;
        transition: 0.2s ease-in-out;
    }

    img {
        width: 32px;
        height: 32px;
        object-fit: contain;
    }
}


@media (max-width:767px) {
    .kpndwaltl .pHdon h2 {
        font-size: 28px;
    }

    .kpndwaltl .Mtstl h1 {
        font-size: 28px;
    }

    .kpndwaltl .DcAtl h1 {
        font-size: 28px;
    }

    .kpndwaltl .byCEp h1 {
        font-size: 28px;

    }

    .kpndwaltl .DcAtl {
        padding-top: 30px;
    }

    .kpndwaltl .byCEp {
        padding-top: 0px;
        padding-bottom: 20px;
    }

}

/** Parkwallet Page Css END **/


/** Explore Page Css START **/

.explore-sec {
    padding: 50px 0 20px 0;

    h2 {
        color: #111827;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 46.5px;
        margin: 0 0 35px 0;
    }

    p {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    @media only screen and (max-width: 991px) {

        .orderimg {
            order: 2;
            margin: 20px 0 0 0;
        }

    }





}

/** Explore Page Css END **/




/*new*/

.btn-primary {
    background-color: #456AFF;
    border-color: #456AFF;
    border: 1px solid #456AFF;
    outline: 0;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;



    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:focus {
        border-color: #456AFF;
        background-color: transparent;
        color: #456AFF;
    }

}



.newimg {
    width: 100%;
    max-width: 16px;
}



/** Document Page Css START **/

.documentpage-content {
    .document-inrbox {
        border: 1px solid #F0F0F0;
        opacity: 0.8600000143051147;
        background-color: #FFF;
        box-shadow: 0px 5.199999809265137px 46.79999923706055px 0px #E2F0FF;
        padding: 30px 15px;
        text-align: center;
        min-height: 240px;
        position: relative;
        transition: .5s all;
        overflow: hidden;
        margin: 0 0 25px 0;

        &:hover {
            &::before {
                transform: scale(1.1);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../views/Document/images/boxlayer.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            z-index: -1;
            transition: .5s all;
        }

        .document-cnt {
            position: relative;
            padding: 0 0 5px 0;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 80%;
                margin: 0 auto;
                height: 1px;
                background-color: #D6D7DC;
            }

            h5 {
                color: #456AFF;
                text-align: center;
                font-size: 13.867px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            h3 {
                color: #111827;
                text-align: center;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
            }
        }



        button {
            background-color: #D3D3D3;
            border-color: #D3D3D3;
            transition: .5s all;

            &:hover,
            &:focus {
                background-color: #456AFF;
                border-color: #456AFF;
                color: #fff;
            }

            min-width: 136px;
            min-height: 33px;
            margin: 30px 0 0 0;
        }

    }
}

/** Document Page Css END **/


/** Page Cmn Css **/

.bluebg-bnrsec {
    background-image: url('../assets/images/cmnblue-bnr.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    min-height: 600px;

    .blubg-bannercnt {
        padding: 30px 0;

        .bluebg-bnrcnt {
            h1 {
                color: #FFF;
                font-size: 65px;
                font-style: normal;
                font-weight: 600;
                line-height: 75px;
                margin: 0 0 30px 0;

                span {
                    font-weight: 400;
                }

            }

            p {
                color: #FFF;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }
        }
    }

    @media only screen and (max-width: 1199px) {

        .blubg-bannercnt .bluebg-bnrcnt h1 {
            font-size: 50px;
            line-height: 60px;
        }
    }

    @media only screen and (max-width: 767px) {

        .blubg-bannercnt .bluebg-bnrcnt h1 {
            font-size: 40px;
            line-height: 50px;
        }
    }


}

/** Hackathon Profile Section Css START **/

.whitebg-box {
    border-radius: 3.345px;
    background: rgba(255, 255, 255, 0.8600000143051147);
    box-shadow: 0px 5.017646789550781px 45.1588249206543px 0px #E2F0FF;
    backdrop-filter: blur(4.18137264251709px);
}

.fs-18 {
    color: #111827;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 166.667% */
}


.hackathon-submisions,
.hackathon-education {
    @extend .whitebg-box;
    padding: 30px 20px;
    margin: 20px 0 0 0;

    h5 {
        @extend .fs-18;
    }

}

.sub-tbl {
    margin: 30px 0 0 0;

    table {
        margin: 0;

        thead {
            background: rgba(246, 246, 246, 0.8600000143051147);

            tr {
                th {
                    color: #111827;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                }
            }


        }

        tbody {
            tr {
                td {
                    color: #111827;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                }
            }
        }

        th,
        td {
            white-space: nowrap;
        }
    }
}


/** Hackathon Profile Section Css END **/


/** Submission Css START **/

.submission-main {
    .submission-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #E6E6E6;
        padding: 0 0 12px 0;

        h5 {
            color: #111827;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            margin: 0;

            span {
                color: rgba(17, 24, 39, 0.50);
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
            }

        }

        .sub-input {
            position: relative;
            min-width: 369px;

            .searchicon {
                position: absolute;
                top: 8px;
                left: 10px;
            }

            input {
                background-color: #F6F6F6;
                border: 1px solid transparent;
                color: #000;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                min-height: 40px;
                padding: 0 20px 0 40px;
                transition: .5s all;

                &::placeholder {
                    color: rgba(17, 24, 39, 0.60);
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }

                &:focus {
                    border-color: #456AFF;
                }

            }
        }

    }

    .submission-inrcnt {
        margin: 20px 0;

        padding: 0 0 12px 0;

        &:not(:last-child) {
            border-bottom: 1px solid #E6E6E6;
        }

        .theme {

            display: inline-block;
            min-width: 65px;

            &.active {
                background-color: #E6E6E6;

                h5 {
                    color: #111827;
                }
            }


            h5 {
                color: rgba(17, 24, 39, 0.60);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                margin: 0;
                text-align: center;
            }

        }

        .submissioninr-head {
            display: flex;
            align-items: center;
            justify-content: space-between;


            h5 {
                color: #111827;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                margin: 0;
            }

            p {
                color: #111827;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                margin: 0;

                span {
                    color: rgba(17, 24, 39, 0.50);
                }

            }

        }

        .sub-para {
            margin: 12px 0 0 0;

            p {
                color: rgba(17, 24, 39, 0.70);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
        }

        .member-user {
            display: flex;
            align-items: center;
        }

        .member-box {
            background-color: #06033E;
            padding: 10px 20px;
            display: inline-flex;


            h3 {
                color: #FFF;
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
                margin: 0;
            }

            span {
                color: #FFF;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin: 0 0 0 10px;
            }


        }

        .userdet {
            display: inline-grid;
            grid-template-columns: 37px auto;
            align-items: center;

            h5 {
                color: #456AFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                margin: 0 0 0 10px;
            }

        }

    }


    @media only screen and (max-width: 767px) {

        .submission-head {
            flex-direction: column;

            .sub-input {
                margin: 12px 0 0 0;
                min-width: unset;
                width: 100%;

                input {
                    width: 100%;
                    max-width: 500px;
                    min-width: unset;
                }

            }

        }

        .member-user {
            flex-direction: column;
        }

    }

    @media only screen and (max-width: 575px) {

        .userdet-main {
            display: flex;
            flex-direction: column;
            margin: 12px 0 0 0;
        }

    }

}

/** Submission Css END **/

/** Idea Team Css START **/

.ideateamsub-main {
    .user-sec {
        border-bottom: 1px solid rgba(0, 0, 0, 0.40);
    }

    .ideatem-userdet {
        display: grid;
        align-items: center;
        grid-template-columns: 40px auto;

        h5 {
            color: #1A212F;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
        }

    }

    .ideatem-tabul {
        margin: 20px 0 0 0;

        .nav {
            border: 0;

            .nav-item {
                &:not(:last-child) {
                    margin: 0 20px 0 0;
                }

                .nav-link {
                    color: #514F78;
                    font-family: Poppins;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    border: 0;
                    padding: 0 0 5px 0;
                    cursor: pointer;
                    position: relative;
                    transition: .5s all;

                    &::before {
                        content: '';
                        width: 0;
                        height: 2px;
                        background-color: #456AFF;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: .5s all;
                    }

                    &:hover {
                        color: #456AFF;

                        &::before {
                            width: 100%;
                        }

                    }

                    &.active {
                        color: #456AFF;
                        background-color: transparent;

                        &::before {
                            width: 100%;
                        }

                    }
                }
            }
        }
    }

    .ideatabcnt-sec {
        padding: 25px 0 50px 0;

        .shoeimg-box {
            background-color: #F9F9F9;
            min-width: 368px;
            min-height: 175px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .fs-16-500 {
            color: #111827;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
        }

        .ideateam-head {
            color: #1A212F;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        p {
            color: rgba(26, 33, 47, 0.80);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            /* 185.714% */
        }


        .hackathon-ideatabcnt {
            .salaryhead {
                display: flex;
                align-items: center;

                .shortlist {
                    background-color: #D1FFE4;
                    padding: 10px;
                    display: inline-block;

                    h6 {
                        color: #3AB16A;
                        font-family: Poppins;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin: 0;
                    }
                }
            }
        }

        .hackathon-teamcnt {
            .teammember-box {
                margin: 35px 0 0 0;

                h6 {
                    color: #111827;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px;
                    /* 187.5% */
                }

                .teamdet-box {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 47px auto;
                    margin: 20px 0 0 0;

                    p {
                        margin: 0;
                    }

                    h5 {
                        color: #06033E;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 30px;
                        margin: 0 0 3px 0;
                    }

                }

            }
        }

        .hackathon-submissiontabcnt {
            .sub-description {
                margin: 35px 0 0 0;
            }

            .tech-box {
                .techname {
                    background-color: #E6E6E6;
                    display: inline-block;
                    padding: 5px 15px;
                    margin: 0 12px 12px 0;

                    h5 {
                        color: rgba(17, 24, 39, 0.70);
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        margin: 0;
                        text-align: center;
                    }
                }
            }

            .graybox {
                background-color: #F9F9F9;
                padding: 12px 20px;
                min-height: 54px;
            }

            .slidermain-cnt {
                background-color: #F9F9F9;
                padding: 20px;
                margin: 30px 0 0 0;

                img {
                    margin: 0 auto;
                }

            }

            .slidersub-cnt {
                margin: 30px 0 0 0;


                .slick-slide {
                    cursor: pointer;
                }

                .slick-slide.slick-active.slick-current {
                    .slider-item {
                        border: 1px solid #456AFF;
                    }
                }

                .slider-item {
                    background-color: #F9F9F9;
                    padding: 10px;

                    img {
                        width: 100%;
                        max-height: 120px;
                    }
                }
            }


        }


    }

}

/** Idea Team Css START **/

/** 27-07-2023 **/

.lefthead-txt {
    display: inline-grid;
    grid-template-columns: 22px auto;
    align-items: center;

    button {
        background-color: transparent;
        padding: 0;
        border: 0;
        outline: 0;
    }

    h5 {
        color: #111827;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        margin: 0;
        padding: 0 0 0 5px;
    }
}

.input-filed {
    color: #111827;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 187.5% */
}

/** Hackathon Idea Css START **/

.hackathon-ideateammain {
    .ideateam-gridmain {
        padding: 0 0 30px 0;
    }


    .hackathon-leftcnt {
        border-radius: 3.345px;
        opacity: 0.8600000143051147;
        background: #F8F8F8;
        // background: rgba(248, 248, 248, 0.8600000143051147);

        padding: 15px;

        .teamidea-userprofile {
            border-radius: 3.345px;
            background: rgba(255, 255, 255, 0.8600000143051147);
            padding: 15px;

            .hackathon-profileImg {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin: 0 auto;
            }

            .hackathon-usernamebox {
                border-radius: 4px;
                background-color: #F7F8F9;
                padding: 5px 15px;
                margin: 10px 0 0 0;
                display: inline-block;

                .username-grid {
                    display: grid;
                    grid-template-columns: auto 20px;
                    align-items: center;

                    h5 {
                        color: #1A212F;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin: 0;
                    }

                    button {
                        background-color: transparent;
                        padding: 0;
                        border: 0;
                        outline: 0;
                    }

                }

            }

            .hackathon-email {
                margin: 5px 0 0 0;

                p {
                    color: #1A212F;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px;
                    /* 225% */
                    text-align: center;
                }
            }



        }

        .teamidea-tabul {
            margin: 25px 0 0 0;

            .nav {
                border-bottom: 0;

                .nav-item {
                    .nav-link {
                        color: #484848;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        border: 0;
                        border-radius: 0;
                        cursor: pointer;
                        padding: 0;

                        &:hover,
                        &.active {
                            color: #456AFF;
                            background-color: transparent;
                        }

                    }
                }

            }
        }


    }



    .teamidea-head {


        .righthead-txt {
            button {
                border-radius: 4px;
                background-color: #456AFF;
                border: 1px solid transparent;
                padding: 5px 12px;
                outline: 0;
                transition: .5s all;

                &:hover {
                    border-color: #456AFF;
                    background-color: transparent;

                    .newidea-grid {
                        svg {
                            path {
                                fill: #456AFF;
                            }
                        }

                        h5 {
                            color: #456AFF;
                        }
                    }


                }

                .newidea-grid {
                    display: grid;
                    grid-template-columns: 22px auto;
                    align-items: center;

                    svg {
                        transition: .5s all;
                    }

                    h5 {
                        color: #FFF;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 28px;
                        margin: 0 0 0 7px;
                    }

                }

            }
        }

    }

    .teamidea-count {
        border-bottom: 1px solid rgba(0, 0, 0, 0.40);
        margin: 20px 0 0 0;

        h5 {
            color: #456AFF;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 200% */
        }

    }

    .idea-testingbox {
        background-color: #F9F9F9;
        padding: 10px;
        margin: 20px 0 0 0;

        .ideatest-flex {
            display: grid;
            align-items: center;
            grid-template-columns: auto 25px;

            .testbox-cnt {
                padding: 0 20px 0 0;

                h5 {
                    color: #111827;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                    /* 166.667% */
                    margin: 0 0 5px 0;
                }

                p {
                    color: #484848;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    /* 185.714% */
                    margin: 0;
                }
            }



            button {
                background-color: transparent;
                border: 0;
                padding: 0;
                outline: 0;
            }

        }

    }

    @media only screen and (min-width: 481px) {

        .hackathon-leftcnt {
            .teamidea-tabul {
                .nav {
                    .nav-item {
                        &:not(:last-child) {
                            margin: 0 15px 0 0;
                        }
                    }
                }
            }
        }

        .teamidea-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


    }


    @media only screen and (min-width: 992px) {

        .ideateam-gridmain {
            display: grid;
            grid-template-columns: 270px calc(100% - 270px);
        }

        .hackathon-leftcnt {
            min-height: 500px;

            .teamidea-tabul {
                .nav {
                    display: inline-block;

                    .nav-item {
                        &:not(:last-child) {
                            margin: 0 0 5px 0;
                        }

                        .nav-link {
                            padding: 5px;
                        }

                    }

                }
            }
        }

        .hackathon-rightcnt {
            margin: 0 0 0 20px;
        }

    }

    @media only screen and (max-width: 991px) {

        .hackathon-rightcnt {
            margin: 25px 0 0 0;
        }

    }


    @media only screen and (max-width: 480px) {

        .hackathon-leftcnt {
            .teamidea-tabul {
                .nav {
                    justify-content: space-between;
                }
            }
        }

        .teamidea-head {
            text-align: center;

            .righthead-txt {
                margin: 10px 0 0 0;
            }
        }
    }

}

/** Hackathon Idea Css END **/

/** About Idea Css START **/

.aboutidea-main {
    margin: 25px 0;

    .aboutidea-inrcnt {
        // background-color: #F9F9F9;
        padding: 20px;
        margin: 20px 0 0 0;

        form {
            input {
                min-height: 62px;
            }

            h6 {
                @extend .input-filed;
            }

            textarea {
                min-height: 303px;
                width: 100%;
            }

            input,
            textarea {
                background-color: #f6f7fc;
                @extend .input-filed;
                border: 1px solid #f6f7fc;
                padding: 20px;
                outline: 0;
                transition: .5s all;

                &:focus {
                    border-color: #f6f7fc;
                }

                &::placeholder {
                    @extend .input-filed
                }
            }

            .fileupload-box {
                border: 1.2px dashed #B9B9B9;
                padding: 20px;
                min-height: 80px;
                margin: 20px 0 0 0;
                max-width: 389px;

                .fileupload-grid {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h3 {
                        color: #111827;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        margin: 0;
                    }

                    label {
                        margin: 0;

                        div {
                            display: grid;
                            align-items: center;
                            grid-template-columns: 24px auto;
                            min-width: 144px;
                            min-height: 40px;
                            border-radius: 4px;
                            background: rgba(69, 106, 255, 0.87);
                            border: 0;
                            padding: 0 15px;
                            outline: 0;


                            h5 {
                                color: #fff;
                                text-align: center;
                                font-family: Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 28px;
                                /* 200% */
                                margin: 0;
                            }



                        }

                    }

                    input {
                        width: 0;
                        height: 0;
                        position: absolute;
                        padding: 0;
                        border: 0;
                    }



                }
            }

            .form-check {
                input {
                    background-color: transparent;
                    padding: 0;
                    border: 0;
                    min-height: unset;
                    margin: 5px 0 0 0;
                }

                label {
                    padding: 0 0 0 5px;
                }
            }

            .publishidea-btn {
                border-radius: 4px;
                background-color: #E3E3E3;
                border: 1px solid transparent;
                color: #BBB;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                /* 200% */
                min-width: 111px;
                min-height: 40px;
                cursor: unset;
            }

        }
    }

    @media only screen and (max-width: 480px) {

        .fileupload-grid {
            flex-direction: column;
        }

    }


}


/** Coming Soon **/

.comingsoon-page {
    min-height: calc(100vh - 384px - 76px);
    background-color: #060604;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 55px;
        font-weight: 600;
        text-align: center;
        color: #fff;
    }

}

/** Hackathon Css **/

.newpage-main {
    form {
        .form-group {
            margin: 30px 0;

            label {
                font-size: 15px;
                font-weight: 500;
            }

            input {
                background-color: #f6f7fc;
                border: 1px solid #f6f7fc;
                outline: 0;
                min-height: 50px;
                width: 100%;
                transition: .5s all;
                color: #000;

                &:focus {
                    border-color:#f6f7fc;
                    background-color: #f6f7fc;
                }

            }



        }

        .cus-fileupload {
            input {
                width: 0;
                height: 0;
                position: absolute;
                padding: 0;
            }

            label {
                background-color: transparent;
                min-height: auto;
                width: 100%;

                .fileupload-flex {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;

                }

            }
        }




    }
}


/** OTP Css START **/

.hackathonotp-page {
    .otp-time {
        margin: 0 0 40px 0;

        h5 {
            color: #111827;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 19.5px;

            span {
                font-weight: 500;
            }

        }
    }

    .otp-input {
        input {
            border-bottom: 1px solid #D2D5E0;
            background-color: transparent;
            border-radius: 0;
            display: inline-block;
            width: 47px;
            padding: 0;
            box-shadow: none;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            text-align: center;
            margin: 5px;
        }
    }

    .otp-seconds {
        color: #456AFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 109.5%;
    }

    .resendotp {
        padding: 0 0 30px 0;

        a {
            color: #456AFF;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%;
            text-decoration-line: underline;
        }
    }

    @media only screen and (max-width: 450px) {
        .otp-input {
            display: grid;
            grid-template-columns: 15% 15% 15% 15% 15% 15%;
            justify-content: space-between;

            input {
                width: 100%;
            }
        }
    }



}

.page-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}




.loader_arrow {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #456aff #0000 #fff #0000;
    border-radius: 50%;
    box-sizing: border-box;
    animation: 1s rotate linear infinite;
}

.loader_arrow:before,
.loader_arrow:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    transform: translate(-10px, 19px) rotate(-35deg);
}

.loader_arrow:after {
    border-color: #456aff #0000 #0000 #0000;
    transform: translate(32px, 3px) rotate(-35deg);
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

.pageloade {
    height: 100vh;
    background-color: #06060470;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 10000;
    backdrop-filter: blur(5px);
}

.page-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader_arrow {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #456aff #0000 #fff #0000;
    border-radius: 50%;
    box-sizing: border-box;
    animation: 1s rotate linear infinite;
}

.loader_arrow:before,
.loader_arrow:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    transform: translate(-10px, 19px) rotate(-35deg);
}

.loader_arrow:after {
    border-color: #456aff #0000 #0000 #0000;
    transform: translate(32px, 3px) rotate(-35deg);
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

//Header CSS

.hdRt {
    min-width: unset;

    .login-menu,
    .reg-menu {
        background-color: transparent;
        border: 1px solid #fff;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active {
            background-color: #456AFF;
            border-color: #456AFF;
            color: #fff;
        }
    }

    .login-menu,
    .reg-menu,
    .sbuilding {
        padding: 5px 10px;
    }
}

@media only screen and (max-width: 1199px) {
    .hdNavMenu .nav-item {
        margin: 6px 19.5px;
    }

    .hdRt {
        min-width: auto;
    }

  

    .sbMenu .hdNavMenu {
        transform: translateX(0%);
    }
}

@media only screen and (max-width: 767px) {
    .sbuilding {
        display: none;
    }
}

@media only screen and (min-width: 576px) {
    .hdrLogo-ltr {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .hdrLogo {
        display: none;
    }
}




.formTwoLabel {
    padding-left: 10px;
}

.cus-textarea {
    background-color: #f6f7fc;
    border: 1px solid #f6f7fc;
    outline: 0;
    min-height: 100px;
    width: 100%;
    transition: .5s all;    
    color: #000;
    padding: 15px;

    &:focus {
        border-color: #f6f7fc;
        background-color: #f6f7fc;
    }

}

/** Leader Board Css START **/

.leaderboard-maincontent {
    padding: 30px 0;

    h5 {
        color: #333;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
    }

    .leaderboard-search {
        margin: 20px 0 0 0;
        width: 100%;
        max-width: 450px;
        position: relative;

        .search-icon {
            position: absolute;
            top: 12px;
            left: 15px;
        }

        input {
            background-color: #f6f7fc;
            min-height: 50px;
            border: 1px solid transparent;
            padding: 5px 20px 5px 42px;

            &:focus {
                border-color: #456AFF;
            }

        }
    }

    .leadertable-main {
        margin: 30px 0 0 0;

        .leaderboard-table {

            border-radius: 4px;
            background-color: #FFF;
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            backdrop-filter: blur(5px);
            padding: 20px;

            table {
                margin: 0;

                thead {
                    tr {
                        th {
                            color: #111827;
                            font-size: 16px;
                            font-weight: 500;
                            border-radius: 4px;
                            background-color: #F4F4F4;
                            backdrop-filter: blur(5px);

                            &:nth-child(2),
                            &:nth-child(3) {
                                text-align: center;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }

                tbody {
                    .flag-td {
                        img {
                            width: 100%;
                            max-width: 30px;
                        }
                    }

                    tr {
                        td {
                            color: #484848;
                            font-size: 14px;
                            vertical-align: middle;
                            line-height: 25px;


                            &:nth-child(2),
                            &:nth-child(3) {
                                text-align: center;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }

                        .userdet {
                            display: inline-grid;
                            align-items: center;
                            grid-template-columns: 40px auto;

                            .user-name {
                                margin: 0 0 0 10px;
                            }
                        }
                    }

                }

                th,
                td {
                    white-space: nowrap;
                }
            }
        }

        .table-show {
            margin: 20px 0 0 0;

            p {
                color: #000;
                font-size: 14px;
                text-align: center;
            }
        }

        .table-pagination {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tableshow {
                display: flex;
                align-items: center;

                p {
                    color: #000;
                    margin: 0;
                }

                select {
                    background-color: #f4f4f4;
                    border: 0;
                    display: inline-block;
                    min-width: 25px;
                    color: #000;
                    margin: 0 0 0 10px;
                }
            }

            .tablepagination-ul {
                display: flex;
                align-items: center;

                button {
                    background-color: #456AFF;
                    color: #fff;
                    border-radius: 5px;
                    padding: 5px 15px;
                    border: 1px solid transparent;
                    outline: 0;
                    transition: .5s all;

                    &:hover {
                        background-color: transparent;
                        border-color: #456AFF;
                        color: #456AFF;
                    }



                    &:hover {
                        text-decoration: none;
                    }
                }


                ul {
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 0;



                    li {
                        margin: 0 5px;

                        a {
                            // background-color: #F6F7FC;
                            padding: 5px;
                            color: #000;
                            border-radius: 50%;
                            padding: 0;
                            width: 30px;
                            height: 30px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;

                            background-color: #f4f4f4;
                            box-shadow: 0px 6px 54px 0px #E2F0FF;
                            -webkit-backdrop-filter: blur(5px);
                            backdrop-filter: blur(5px);
                        }


                    }
                }
            }


        }


        @media only screen and (max-width: 767px) {
            .table-pagination {
                flex-direction: column;

                .tableshow {
                    margin: 0 0 15px 0;
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .tablepagination-ul {
                flex-direction: column;

                ul {
                    margin: 15px 0 !important;
                }
            }
        }

    }

}



/** Cmn Modal Css START **/

.modal-content {
    border-radius: 10px;
    border: 1px solid #E3E5EF;
    background-color: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);

    .closebtn {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        border: 0;
        outline: 0;
        z-index: 99;

    }

    .modal-body {
        padding: 20px;
    }
}

/** Cmn Modal Css END **/

/** Manage Team Css START **/

.manageteampage-maincontent {
    padding: 30px 0;

    .backto-txt {

        a,
        a:hover {
            text-decoration: none;
        }

        a {
            display: flex;
            align-items: center;
        }

        span {
            color: #456AFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }
    }

    .timedate-details {

        margin: 30px 0;

        .datetime-logo {
            border-radius: 4px;
            background-color: #FFF;
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            min-height: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .datetime-cnt {
            // margin: 0 0 0 20px;

            h5 {
                color: #111827;
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
            }

            p {
                color: #111827;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;

                span {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }


    }

    .manageteam-tabsec {
        .manageteam-tabmain {
            border-radius: 4px;
            background-color: #FFF;
            box-shadow: 0px 6px 54px 0px #E2F0FF;
            padding: 20px;

            .manageteam-head {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h1 {
                    color: #111827;
                    font-size: 35px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 50px;
                    margin: 0;
                }

                button {
                    background-color: transparent;
                    padding: 0;
                    border: 0;
                    outline: 0;
                }

            }

            .teamname {
                margin: 30px 0 0 0;

                h5 {
                    font-size: 16px;
                    color: #484848;
                }

                button {
                    background-color: transparent;
                    padding: 0;
                    border: 0;
                    outline: 0;
                    margin: 0 10px;
                }

            }

            .manageteam-tabul {
                margin: 30px 0 0 0;

                ul {
                    border-bottom: 1px solid rgba(17, 24, 39, 0.1);
                    padding: 0 0 10px 0;

                    .nav-item {
                        margin: 0 15px 0 0;

                        .nav-link {
                            color: #484848;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 28px;
                            border: 0;
                            padding: 0;
                            cursor: pointer;

                            &.active {
                                color: #000;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .manageteam-tabcnt {
                margin: 30px 0 0 0;
            }

            .teammember-box {
                display: grid;
                align-items: flex-start;
                grid-template-columns: 40px auto;
                padding: 0 0 30px 0;


                .team-txt {
                    margin: 0 0 0 12px;

                    h5 {
                        font-size: 16px;
                        color: #111827;
                    }

                    p {
                        font-size: 14px;
                        color: #484848;
                        margin: 0;
                        word-break: break-all;
                    }
                }

            }

            .invitation-btn {
                button {
                    border-radius: 4px;
                    background-color: #456AFF;
                    color: #FFF;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 28px;
                    border: 1px solid transparent;
                    transition: .5s all;
                    padding: 3px 15px;
                    outline: 0;

                    &:hover {
                        background-color: transparent;
                        border-color: #456AFF;
                        color: #456AFF;
                    }
                }
            }


        }
    }

    @media only screen and (max-width: 500px) {
        .timedate-details {
            .datetime-logo {
                min-width: 100px;
                min-height: 100px;
            }

            .datetime-cnt {
                margin: 15px 0 0 0;
            }
        }
    }

    @media only screen and (min-width: 501px) {
        .timedate-details {
            display: grid;
            align-items: flex-start;
            grid-template-columns: 100px auto;

            .datetime-cnt {
                margin: 0 0 0 20px;
            }

        }
    }

}

.invitemember-modalcnt {
    margin: 2rem 0 0 0;

    form {
        margin: 1rem 0 0 0;

        label {
            color: #1A2B3B;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%;
            text-align: left;
        }

        input {
            min-height: 50px;
        }

        input,
        textarea {
            border-radius: 6px;
            background-color: #F6F7FC;
            border: 1px solid transparent;
            color: #000;
            font-size: 15px;
            transition: .5s all;

            &:focus {
                border-color: #456AFF;
            }
        }
    }

    h5 {
        color: #111827;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    .cancel-btn,
    .sendinvi-btn {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .cancel-btn {
        background-color: transparent;
        padding: 0;
        border: 0;
        outline: 0;
        color: #456AFF;
    }

    .sendinvi-btn {
        border-radius: 4px;
        background-color: #456AFF;
        color: #FFF;
    }
}







/** Manage Team Css END **/


.input-control {

    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, b
}

/** Leader Board Css END **/
/** OTP Css END **/

/** Hackathon Css **/

/** Idea Team Css START **/

/** About Idea Css END **/

/** Page Cmn Css **/


.atchtex{
    h3{
        color: #111827;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;

    }

}

.hytj{
    background-color: #5d7dff !important;
    &:hover{
        color: #fff;
    }
}