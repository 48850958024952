
.stakepage-content 
{


    @keyframes moveY{
        0%
        {
            transform: translateY(-20px);
         }
        50%
        {
            transform: translateY(25px);
        }
        100%
        {
            transform: translateY(-20px);
        }
    }


    .stakebnr-sec 
    {
        background-image: url('../images/bluebg-bnr.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        padding: 30px 0 30px 0;

        .stakebnr-rightcnt 
        {
            img 
            {
                animation: moveY 5s linear infinite;
            }

        }

        h1 
        {
            color: #FFF;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: 75px;
            margin: 0 0 25px 0;
        }
        p 
        {
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }

   

    h2 
    {
        color: #111827;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
    }

    p 
    {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    .stake-bgbox, .stakeinr-cnt, .stake-tbl 
    {
        border-radius: 4px;
        // opacity: 0.8600000143051147;
        background-color: #FFF;
        box-shadow: 0px 6px 54px 0px #E2F0FF;
        backdrop-filter: blur(5px);
        padding: 20px;
    }

    .stake-tbl 
    {
        thead 
        {
            tr 
            {
                th 
                {
                    color: #0D011E;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 103.5%;
                }
            }
        }

        tbody 
        {
            tr 
            {
                td 
                {
                    color: #848199;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                }
            }
        }

        th, td 
        {
            white-space: nowrap;
        }
    }

    

    @media only screen and (max-width: 575px) {

        .stakebnr-sec 
        {
            h1 
            {
                font-size: 50px;
            }
        }

        h2 
        {
            font-size: 30px;
            line-height: 40px;
        }

    }

    @media only screen and (max-width: 991px) {

        .orderImg 
        {
            order: 2;
        }
    }

    
}