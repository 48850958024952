
/** Cmn Css **/ 

h2 
{
    color: #111827;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px;
    margin: 0 0 25px 0;
}

p 
{
    color: rgba(17, 24, 39, 0.60);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.cmnwhite-box 
{
    border-radius: 4px;
    // opacity: 0.8600000143051147;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);

    h5 
    {
        color: #111827;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin: 0 0 15px 0;
    }
    p 
    {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

}

.colorbg-box 
{
    padding: 30px;
    height: 100%;

    &.pink-bg 
    {
        background-color:#E8D6FF;
    }
    &.vilot-bg 
    {
        background-color:#C2E9FF;
    }
    &.lightgreen-bg 
    {
        background-color:#D7FFD1;
    }
    &.lightyellow-bg 
    {
        background-color:#FFF0C9;
    }
    &.purple-bg 
    {
        background-color:#D0D9FF;
    }

    h5 
    {
        color: #111827;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin: 10px 0 20px 0;
    }

}




.bluebgbnr-sec 
{
    background-image: url('../images/banner-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 50px 0;

    .cmnbnr-txt 
    {

        h1, p 
        {
            color: #fff;
        }

        h1
        {
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: 65px;
            margin: 0 0 30px 0;

            span 
            {
                font-weight: 400;
            }

        }

        p 
        {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }

    }

    @media only screen and (max-width: 767px) {

        .cmnbnr-txt 
        {
            h1 
            {
                font-size: 40px;
                line-height: 50px;
            }
        }


    }

}


.whatdecentralization-sec 
{   
    padding: 50px 0;

    .whatdec-inrbox 
    {
        border-radius: 4px;
        // opacity: 0.8600000143051147;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 6px 54px 0px #E2F0FF;
        backdrop-filter: blur(5px);
        position: relative;
        padding: 40px 25px;

        &::before 
        {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../images/linear-bg.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            opacity: 0.5;
            z-index: -1;
        }

        h5 
        {
            color: #111827;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 45px;
            padding: 0 30px;
        }

        p 
        {
            padding: 0 30px;
            margin: 25px 0 0 0;
        }
    }

    @media only screen and (max-width: 767px) {

        .whatdec-inrbox 
        {
            padding: 20px;

            h5 
            {
                font-size: 28px;
                line-height: 45px;
                padding: 0;
            }

            p 
            {
                padding: 0;
            }

        }


    }

    

}


.benifitparkchain-sec 
{
    .benefit-inbox 
    {
        @extend .cmnwhite-box;
        padding: 30px 25px;
        margin: 0 0 30px 0;
        min-height: 520px;
        transition: .5s all;

        &:hover 
        {
            
            margin: -10px 0 0 0;
        }

        .benefit-para 
        {
            min-height: 190px;
        }
        
        .benift-img 
        {
            text-align: center;
            margin: 10px 0;
        }

    }
}



@media only screen and (max-width: 767px) {

  h2 
  {
    font-size: 35px;
    line-height: 45px;
  }


}

