// Event Details
.evnPg
{
    .kpEvDEtail
    {
        padding: 30px;

        ul
        {
            li{
                font-weight: 400;
                font-size: 14px;
                color: #484848;
                line-height: 26px;
            }
        }
    }

    .kpEvDEtail p
    {
        padding-bottom: 14px;
    }

}


// Event List
.evLsCon
{
    @media only screen and (min-width: 992px)
    {
        display: flex;
        flex-direction: column;
        height: 100%;        
        .evLsIg
        {
            min-height: 277.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            img
            {
                max-height: 277.5px;
                object-fit: contain;            
            }
        }
        .evLsTxCon
        {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 277.5px);
        }
    }
}

// .newtext{
//     color: red;
// }
  

.playstore-btn 
{
    background-color: transparent;
    border: 0;
    outline: 0 !important;
    width: 100%;
    max-width: 145px;
    padding: 0;
}

.playstorebtn-box 
{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 575px)
{
    .playstorebtn-box
    {
        flex-direction:column;

        .playstore-btn 
        {
            margin: 5px 0;
        }

    }

}

.devresoure-para 
{
   min-height:20em;
}

.ecs-img 
{
    width: 100%;
    max-width: 80%;
}

.pd-para 
{
    min-height: 15em;
}


/** Hackathon **/ 

.projecdet-sec 
{
    position: relative;
    min-height: 350px;

    .tophack-cnt 
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .hackathon-leftcnt *
    {
        color: #fff;

    }

    .hackathon-leftcnt 
    {
        
        .tophack-cnt 
        {
            h5 
            {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 28px;
            }

            h3 
            {
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
            }

        }

        .prodet-cnt 
        {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 50px 0 0 0;
        }

        .proinr-cnt 
        {
            text-align: center;
            position: relative;

            p 
            {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px; /* 175% */
            }

            h2 
            {
                color: #FFF;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                margin: 0 0 10px 0;
            }

        } 

    }

}

@media only screen and (min-width: 1200px) {

    .projecdet-sec 
    {
        &::after 
        {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background-image: url('../assets/images/menbnr.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
        }

        &::before 
        {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #456AFF;
        }

        .hackathon-leftcnt 
        {
            padding: 25px 25px 25px 0;
            
            .proinr-cnt 
            {
                text-align: center;
                position: relative;
    
                &:nth-child(2)
                {
                    border-left: 1px solid rgba(255, 255, 255, 0.37);
                    border-right: 1px solid rgba(255, 255, 255, 0.37);
                    padding: 0 20px;
                }
            }
    
        }

    }
}

@media only screen and (max-width: 1199px) {

    .projecdet-sec 
    {
        background-color: #456AFF;
        padding: 30px 0;

      
    

        .hackathon-leftcnt .prodet-cnt
        {
            flex-direction: column;
        }

        .proinr-cnt 
        {
            &:nth-child(2)
            {
                border-top: 1px solid rgba(255, 255, 255, 0.37);
                border-bottom: 1px solid rgba(255, 255, 255, 0.37);
                padding: 20px 0;
                
            }
        }

    }

}
/** Hackathon **/ 



.whytxt 
{
    h3 
    {
        font-size: 40px;
        line-height: 50px;
        color: #111827;
        text-align: center;
        margin: 0 0 12px 0;
    }

}

.css-b62m3t-container {
    z-index: 999;
}

.hckEveConTx h2 {
    min-height: 90px;
}

.hdNavMenu .userimg{
    width: 30px;
    height: 30px;
    border-radius: 50px;
}
.hdRt{
    display: flex;
    align-items: center;
}

.hckDtTab
{
    height: 100%;
}


.fileupload-box-tl{
    .fileupload-box {
        border: 1.2px dashed #B9B9B9;
        padding: 20px;
        min-height: 80px;
        margin: 20px 0 0 0;
        max-width: 389px;

        .fileupload-grid {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                color: #111827;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                margin: 0;
            }

            label {
                margin: 0;

                

            }

            input {
                width: 0;
                height: 0;
                position: absolute;
                padding: 0;
                border: 0;
            }



        }

        .btnns {
            display: grid;
            align-items: center;
            grid-template-columns: 24px auto;
            min-width: 144px;
            min-height: 40px;
            border-radius: 4px;
            background: rgba(69, 106, 255, 0.87);
            border: 0;
            padding: 0 15px;
            outline: 0;


            h5 {
                color: #fff;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                /* 200% */
                margin: 0;
            }



        }
    }


}


.min-100{
    min-width: 100px;
}



.ftr-metamask 
{
    margin: 0 0 15px 0;

    button 
    {
        background-color: #456AFF;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        border-radius: 3px;
        padding: 0.3rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s all;

        img 
        {
            padding: 0 0.5rem 0 0;
        }

        &:hover 
        {
            background-color: transparent;
            border-color:#456AFF;
            color: #000;
        }


    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px)
    {

        .navbar-brand
        {
            img 
            {
                width: 100%;
                max-width:130px;
            }
        }

        .hdNavMenu .nav-item
        {
            margin: 6px 10px;


        }
    }

    .ftScl 
    {
        margin: 0 0 1rem 0;
    }


    @media only screen and (max-width: 991px) 
    {
        .hdRt 
        {
            .hdNavMenu
            {
                height: auto;
            }
        }
        .mobileNav 
        {
            padding: 80px 0 0 0;
        }
    }


    .videoINput 
    {
        border: 0 !important;
        
    }


   .videolable 
   {
        background-color: #484848;
        border: 1px dashed #B9B9B9;
        padding: 10px 10px;
   }