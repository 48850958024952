.bluebgbnr-sec 
{
    background-image: url('../images/banner-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding: 50px 0;

    .cmnbnr-txt 
    {

        h1, p 
        {
            color: #fff;
        }

        h1
        {
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: 65px;
            margin: 0 0 30px 0;

            span 
            {
                font-weight: 400;
            }

        }

        p 
        {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }

    }

    @media only screen and (max-width: 767px) {

        .cmnbnr-txt 
        {
            h1 
            {
                font-size: 40px;
                line-height: 50px;
            }
        }


    }

}

.whitebg-box 
{
    border-radius: 4px;
    opacity: 0.8600000143051147;
    background-color: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
}

.learn-inrcnt 
{
    padding: 0 20px 20px 0;
    @extend .whitebg-box;

    .no-box 
    {
        background: rgba(30,112,254,.1);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 70px;
        min-height: 70px;
    

        h4 
        {
            color: #1E70FE;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            margin: 0;
        }
    }

    h2 
    {
        color: #111827;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }

    .rpc-inrcnt 
    {
        padding: 15px 20px 20px 20px;
    }

    .rpcsettings-flex 
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0 0 0;

        

        h5 
        {
            color: #111827;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
        }

        p 
        {
            color: rgba(17, 24, 39, 0.60);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
        }

    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {

        .rpcsettings-flex
        {
            div 
            {
                margin: 0 10px;
            }
        }


    }

    @media only screen and (max-width: 767px) {

        .rpcsettings-flex
    {
        display: block;
        text-align: center;
    }

    }

}