
/** Cmn Css **/ 

h2 
{
    color: #111827;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px;
    margin: 0 0 25px 0;
}

p 
{
    color: rgba(17, 24, 39, 0.60);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.cmnwhite-box 
{
    border-radius: 4px;
    // opacity: 0.8600000143051147;
    background: #FFF;
    box-shadow: 0px 6px 54px 0px #E2F0FF;
    backdrop-filter: blur(5px);

    h5 
    {
        color: #111827;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin: 0 0 15px 0;
    }
    p 
    {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        
    }

}


.benifitparkchain-sec 
{
    .benefit-inbox 
    {
        @extend .cmnwhite-box;
        padding: 30px 25px;
        margin: 0 0 30px 0;
        min-height: 520px;
        transition: .5s all;

        h5, p 
        {
            text-align: center;
        }

        

        &:hover 
        {
            
            margin: -10px 0 0 0;
        }

        .benefit-para 
        {
            min-height: 190px;
        }
        
        .benift-img 
        {
            text-align: center;
            margin: 10px 0;
        }

    }
}


.note 
{
    color: #111827;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
}