.hckcrFrm {
   .input-group
   {
      background-color: #e8e8e8;
      border-color: #e8e8e8;
      padding-right: 10px;
      border-radius: 6px;
   }   
}


.hckcrPgInrIgTk
{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   .hckCrPgIg
   {
      margin-top: auto;
      @media only screen and (min-width: 992px)
      {
         margin-bottom: auto;
      }
   }
}


/** Add Content Css START **/ 

.addcontent-main 
{
    padding: 1rem 0 2rem 0;

    form
    {
        label 
        {
            color: #1A2B3B;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%;
        }

        input, textarea 
        {
            background-color: #f6f7fc;
            border: 1px solid #f6f7fc;
            color: #000;
            font-size: 14px;
            font-weight: 500;
        }

        input 
        {
            border-radius: 6px;
            padding: 5px 15px;
            min-height: 45px;

            &:focus
            {
                background-color: #f6f7fc;
                border: 1px solid #f6f7fc;
            }

        }
        textarea{
            &:focus
            {
                background-color: #f6f7fc;
                border: 1px solid #f6f7fc;
            }
        }

        textarea 
        {
            min-height: 220px;
            resize: none;
        }

        .date-input 
        {
            position: relative;

            .date-icon 
            {
                position: absolute;
                top: 10px;
                left: 10px;
            }

            input 
            {
                padding-left: 40px;
            }

        }

        button 
        {
            min-width: 174px;
            min-height: 42px;
            margin: 1rem 0 0 0;
        }

    } 
}

/** Add Content Css END **/ 