// Register Content Start
.hckcrPg {
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #F3F3FD;
   padding: 1rem 0px;

   .container {
      width: 100%;
   }

   .hckcrPgCon {
      border-radius: 50px;
      background-color: #ffffff;
      padding: 25px 20px;

      .hckcrPgR {
         justify-content: flex-end;
      }
   }
}



.hckcrPgInrIgTk {
   border-radius: 50px;
   background: #1A212F;
}

.hckCrPgIg {
   text-align: right;
   margin-bottom: 10px;
}

.hckCrPgTx h1 {
   font-size: 30px;
   color: #ffffff;
   font-weight: 500;
   line-height: 1.2;
   margin-bottom: 0px;
}

.hckCrPgTx {
   padding: 3px 30px 20px;
}

.hckCrPgTx p {
   font-size: 14px;
   color: #ffffff;
   font-weight: 300;
   margin: 0px;
   line-height: 30px;
}





.hckcrPgLgo {
   margin-bottom: clamp(2rem, 5vw, 3.75rem);
}

.hckcrFrmR {
   margin-right: -12px;
   margin-left: -12px;
}

.hckcrFrmC {
   padding-right: 12px;
   padding-left: 12px;
}

.hckcrFrm label {
   font-size: 15px;
   color: #1A2B3B;
   line-height: 1.375;
   font-weight: 400;
   &.f13
   {
      font-size: 13px;
   }
}

.hckcrFrm label span {
   color: #456AFF;
}

.hckcrFrm {
   .form-control {
      border-radius: 6px;
      background-color: #e0e4f2;
      border-color: #e0e4f2;
      height: calc(1.5em + 0.75rem + 2px + 5.5px);
      font-size: 15px;
   }

   select {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.75L5 5.75L9 1.75' stroke='%23111827' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: calc(100% - 18px) center;
      appearance: none;
      cursor: pointer;
   }

}
.css-b62m3t-container{
   border-radius: 6px;
   background-color: #f6f7fc;
   border-color: #F6F7FC;
   height: calc(1.5em + 0.75rem + 2px + 5.5px);
   font-size: 15px;
   .css-1c2yw-control{
      background-color: #f6f7fc;
     border: none;
   }

}

.hckcrFrm .form-group {
   padding-bottom: 0.5rem;
}

.hckcrFrm .custom-checkbox .custom-control-label::before {
   width: 18px;
   height: 18px;
   top: 0.5px;
   border-radius: 3px;
   border: 2px solid #D2D2E8;
}

.hckcrFrm .custom-checkbox .custom-control-label::after {
   width: 18px;
   height: 18px;
   top: 0.5px;
}

.hckcrFrm .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
   background-color: #456AFF;
   border-color: #456AFF;
}

.hckcrFrm .custom-control-label {
   cursor: pointer;
}

.hckcrFrmSbmRd {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: space-between;
   padding: clamp(1rem, 4vw, 3rem) 0px 1rem;
}

.hckcrFrmSbmRd>* {
   margin-bottom: 0.5rem;
}

.btn-17442 {
   min-width: 174px;
   border-radius: 4px;
   font-size: 16px;
   font-weight: 500;
   padding: 8px 12px;
}

.hckCrRd p {
   font-size: 15px;
   color: #1A2B3B;
   margin: 0px;
}

.hckCrRd p a {
   display: block;
   text-align: right;
   text-decoration: underline;
}

.hckcrPgC.lt {
   margin: 0 auto;
}

@media only screen and (max-width: 1199px) {

   .hckcrPg .hckcrPgCon,
   .hckcrPgInrIgTk {
      border-radius: 30px;
   }
}

@media only screen and (max-width: 991px) {
   .hckcrPgR {
      flex-direction: column-reverse;
   }

   .hckcrPg .hckcrPgCon {
      padding: 0px;
   }

   .hckCrPgIg {
      text-align: center;
   }

   .hckCrPgTx {
      text-align: center;

      h1 {

         br {
            display: none;
         }
      }
   }

   .hckcrPgInrC {
      padding: 26px 26px 10px;
   }

   .hckcrPgLgo {
      text-align: center;
   }
}

@media only screen and (max-width: 575px) {

   .hckcrPg .hckcrPgCon,
   .hckcrPgInrIgTk {
      border-radius: 16px;
   }

   .hckcrPgInrC {
      padding: 26px 20px 10px;
   }

   .hckcrFrmSbmRd {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      padding-top: 0px;
   }

   .hckCrRd p a {
      display: inline-block;
      margin-top: 4px;
      margin-left: 6px;
   }

   .hckCrPgTx h1 {
      font-size: 26px;
   }

   .hckCrPgTx p {
      font-size: 13px;
   }

   .hckCrPgIg img {
      max-height: 250px;
   }
}

@media only screen and (max-width: 399px) {
   .hckCrPgTx h1 {
      font-size: 24px;
   }

   .btn-17442 {
      font-size: 14px;
      min-width: 164px;
   }

   .hckCrPgTx {
      padding: 3px 16px 20px;
   }
}

// Register Content End





// Modal Content Start
.kphckMdlBkDrp {
   background-color: rgba(26, 33, 47, 0.20);
   opacity: 1;

   &.show {
      opacity: 1;
      backdrop-filter: blur(3px);
   }
}


.kphckMdl .modal-content {
   border-radius: 10px;
   border: 1px solid #E3E5EF;
   background-color: #ffffff;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}

.btnMdlcls {
   position: absolute;
   top: 14px;
   right: 8px;
}

.kpHckMdlIc {
   text-align: center;
   padding: 6px 0px 3px;
}

.kpHckMdlTx {
   text-align: center;
   padding: 0px 10px 1rem;
}

.kpHckMdlTx h4 {
   font-size: 22px;
   color: #2A2A2A;
   font-weight: 500;
}

.kpHckMdlTx p {
   font-size: 14px;
   line-height: 26px;
   color: #070606;
   font-weight: 400;
}

.kpHckMdlTx p a {
   display: block;
   color: #456AFF;
}

// Modal Content End



// Login page Content Start
.fgtLk {
   text-align: right;
}

.fgtLk a {
   color: #456AFF;
}

.RemfgtLk {
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: space-between;
}

@media only screen and (max-width: 429px) {
   .fgtLk a {
      font-size: 15px;
   }
}

@media only screen and (max-width: 359px) {
   .RemfgtLk {
      justify-content: center;
   }
}

// Login page Content End



// Profile page Content Start
.hckPrfPg {
   min-height: calc(100vh - 70px - 384px);
}

.hckPrfDtC {
   padding-bottom: 2rem;
}

.hckPrfDt {
   padding: 12px 0px 2.25rem;
   margin-bottom: 0.125rem;
}


.hckPrfPsn {
   min-width: 270px;
   padding: 30px 26px;
   height: 100%;
}

.hckPrfCd::before {
   content: '';
   border-radius: 3.345px;
   opacity: 0.8600000143051147;
   background-color: #ffffff;
   box-shadow: 0px 5.017646789550781px 45.1588249206543px 0px #E2F0FF;
   backdrop-filter: blur(4.18137264251709px);
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100%;
   height: 100%;
   pointer-events: none;
}

.hckPrfCd,
.hckPrfCd>* {
   position: relative;
}



.hckPrfDp {
   position: relative;
   margin-bottom: 28px;
}

.hckPrfDp>img {
   width: 100px;
   height: 100px;
   object-fit: cover;
   border-radius: 50%;
}

.mdrIcG {
   width: 41px;
   height: 42px;
   position: relative;
}

.mdrIcBg {
   border-radius: 5px;
   background: radial-gradient(107.08% 85.59% at 86.30% 87.50%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #456AFF;
   display: block;
   width: 23px;
   height: 26px;
}

.mdrIcCn {
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   width: 34px;
   height: 37px;
   top: 5px;
   left: 7px;
}

.mdrIcCn::before {
   content: '';
   border-radius: 10px;
   opacity: 0.800000011920929;
   background-color: #ffffff;
   box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
   position: absolute;
   top: 0px;
   left: 0px;
   display: block;
   width: 100%;
   height: 100%;
}

.mdrIcCn img {
   position: relative;
}

.hckPrfTxG {
   padding-bottom: 22px;
}

.hckPrfTxLb {
   color: #1A212F;
   font-size: 14px;
   font-weight: 400;
   line-height: 27px;
}

.hckPrfTxVl {
   font-size: 14px;
   font-weight: 500;
   color: #1A212F;
}

.btn-14535 {
   min-width: 145px;
   font-size: 15px;
   font-weight: 500;
}



.hckPrfDtR {
   margin-right: -10px;
   margin-left: -10px;
}

.hckPrfDtC {
   padding-right: 10px;
   padding-left: 10px;
}


.hckPfCnDt {
   padding: 38px 30px;
   margin-bottom: 2rem;
}

.hckPfCnDtMsg {
   font-weight: 400;
   line-height: 26px;
   color: #070606;
   margin-bottom: 1.5rem;
}


.mdrIcG.v2 {
   width: 50px;
   height: 51px;
}

.mdrIcG.v2 .mdrIcBg {
   width: 29px;
   height: 32px;
}

.mdrIcG.v2 .mdrIcCn {
   width: 42px;
   height: 46px;
}

.hckPfCnDtLb {
   color: #1A212F;
   font-size: 14px;
   padding: 3px 0px;
}

.hckPfCnDtVl {
   color: #1A212F;
   font-weight: 500;
   font-size: 18px;
   line-height: normal;
}

.hckPfCnDtIc {
   margin-bottom: 12px;
}


.hckPfCnDtC {
   padding-top: 2rem;
}




.hckPfErSv {
   padding: 38px 30px 28px;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
}

.hckPfErSvLt {
   display: inline-flex;
   flex-wrap: wrap;
   align-items: center;
}

.mdrIcG.v3 {
   width: 88px;
   height: 91px;
}

.mdrIcG.v3 .mdrIcBg {
   width: 51px;
   height: 56px;
}

.mdrIcG.v3 .mdrIcCn {
   width: 74px;
   height: 81px;
   left: 15px;
   top: 10px;
}

.hckPfErSvLt .mdrIcG {
   margin-right: 30px;
}

.hckPfErLb {
   color: #070606;
   font-size: 15px;
   line-height: normal;
}

.hckPfErVl {
   font-size: 18px;
   font-weight: 500;
   color: #456AFF;
   line-height: normal;
}

.hckPrSvR {
   margin-right: -4px;
   margin-left: -4px;
   align-items: flex-end;
}

.hckPrSvC {
   padding-left: 4px;
   padding-right: 4px;
}

.btn-15337 {
   min-width: 153px;
   font-weight: 500;
}

.hckPrSvNt {
   font-size: 13px;
   color: #1A2B3B;
}

.hckPrSvNt span {
   color: #456AFF;
}

.hckPfErSvRt {
   flex: 0 0 58%;
}

@media only screen and (max-width: 1199px) {
   .hckPfErSvRt {
      flex: 0 0 100%;
   }

   .hckPfErSvLt {
      margin-bottom: 1.75rem;
   }

   .hckPrfDt {
      padding-bottom: 1rem;
   }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
   .hckPrfPsn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
   }

   .hckPrfDp {
      flex: 0 0 100%;
   }

   .hckPrfTxG,
   .hckPrfEd {
      flex: 0 0 50%;
   }
}

@media only screen and (max-width: 767px) {
   .hckPfCnDtMsg {
      margin-bottom: 0.5rem;
   }
}

@media only screen and (max-width: 575px) {

   .hckPrfPsn,
   .hckPfCnDt {
      text-align: center;
   }

   .hckPfCnDt .mdrIcG {
      margin: 0px auto;
   }

   .hckPrSvC {
      flex: 0 0 100%;
   }
}

@media only screen and (max-width: 374px) {
   .hckPfErSvLt .mdrIcG {
      margin-right: 20px;
   }
}

@media only screen and (max-width: 359px) {
   .hckPfErSvLt .mdrIcG {
      margin-right: 0px;
      margin-bottom: 20px;
   }
}

// Profile page Content End



// Profile Edit page Content Start
.hckEdtPrf {
   padding: 1rem 0px;
   text-align: center;
}

.hckPrfDp.v2 {
   margin: 0px;
   display: inline-block;
   position: relative;
}

.hckPrfDp.v2 input {
   position: absolute;
   width: 100%;
   left: 0px;
   opacity: 0;
}

.hckPrfDp.v2 label {
   position: absolute;
   top: 18px;
   right: -18px;
   cursor: pointer;
}



.hckEdtPrfIpTy {
   border-radius: 4px;
   background-color: #f6f7fc;
   margin-bottom: 2.375rem;
   color: #2A2A2A;
   font-size: 14px;
   font-weight: 600;
   padding: 8px 19px;

   @media only screen and (max-width: 575px) {
      margin-bottom: 1.25rem;
   }
}

.hckEdtPrfIp {
   padding: 1.125rem 0px;
}

.hckPrfEd {
   padding-bottom: 1rem;
}

.prfEdtSbm {
   padding-top: 5px;
}

// Profile Edit page Content End


// Hackathon Form Edit 1 page Content Start
.hckLsBnrIcTx {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
}

.hckLsBnrIc {
   width: 100px;
   height: 100px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background-color: #ffffff;
   border-radius: 50%;
   margin-right: 20px;
   padding: 15px;
}

.hckLsBnrTx p {
   margin: 0px;
   font-size: 14px;
   color: #ffffff;
   line-height: 26px;
}

.hckLsBnrIg.v2 {
   text-align: right;
}

@media only screen and (max-width: 1199px) {
   .hckLsBnrTx h1 {
      font-size: 36px;
   }
}

@media only screen and (max-width: 991px) {
   .hckLsBnrIcTx {
      justify-content: center;
      padding: 1.5rem 0px;
   }

   .hckLsBnrIcTx .hckLsBnrTx {
      text-align: left;
      padding: 0.5rem 0px;
   }
}

@media only screen and (max-width: 767px) {
   .hckLsBnrIcTx {
      flex-direction: column;
   }

   .hckLsBnrIc {
      margin-right: 0px;
      margin-bottom: 10px;
   }

   .hckLsBnrIcTx .hckLsBnrTx {
      text-align: center;

      h1 {
         font-size: 34px;
      }
   }
}

@media only screen and (max-width: 575px) {
   .hckLsBnrIcTx .hckLsBnrTx h1 {
      font-size: 30px;
   }

   .hckLsBnrIc {
      width: 80px;
      height: 80px;
   }
}







.hckEdtPrfIp h4 {
   font-size: 22px;
   font-weight: 500;
   color: #2A2A2A;
   padding-bottom: 1rem;
   @media only screen and (max-width: 575px)
   {      
      font-size: 20px;
      padding-bottom: 0.5rem;
   }
}
// Hackathon Form Edit 1 page Content End


// Hackathon Form Edit 2 page Content Start
.hckEdtPrfIpTl {
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   justify-content: space-between;
}

.hckEdtPrfIpTl .btn {
   font-size: 18px;
   color: #456AFF;
   line-height: 1.25;
   padding: 0px 12px;
   text-decoration: underline;
}
@media only screen and (max-width: 575px)
{
   .hckEdtPrfIpTl
   {
      padding-bottom: 0.5rem;
   }
}
// Hackathon Form Edit 2 page Content End