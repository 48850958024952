

.bullet-ul 
{
    list-style-type: none;
    padding: 0;
    margin: 0;

    li 
    {
        position: relative;
        color: #111827;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        padding: 0 0 0 15px;
        margin: 0 0 8px 0;

        &::before 
        {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: #111827;
            border-radius: 50%;
        }

    }
}

.benefitpara-cnt 
{
    min-height: 400px;
}

.benefit-inbox 
{
    height: 100%;

   


    h5, p 
    {
        text-align: left !important;
    }


    button 
    {
        min-width: 120px;
        min-height: 40px;
    }
}

@media only screen and (max-width: 767px) {

    .nftdocument-pagecnt 
    {
        .cmnbnr-txt 
        {
            h1 
            {
                font-size: 30px;
                line-height: 40px;
            }
        }
    }
}
