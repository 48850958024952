.parkscan-pagecont {

	h2 {
		color: #111827;
		text-align: center;
		font-size: 40px;
		font-style: normal;
		font-weight: 600;
		line-height: 68px;
	}

	.psbanner-sec {
		background-image: url('../images/cmnbnr.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% 100%;
		min-height: 600px;

		.row {
			padding: 80px 0 0 0;
		}

		h1 {
			color: #FFF;
			font-size: 76px;
			font-style: normal;
			font-weight: 600;
			line-height: 74.5px;
			margin: 0 0 40px 0;
		}

		p {
			color: #FFF;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 28px;
		}

		@media only screen and (max-width: 767px) {

			h1 {
				font-size: 38px;
				line-height: 50px;
			}
		}

	}

	.ulinr {
		display: grid;
		grid-template-columns: 30px auto;
		margin: 0 0 20px 0;

		.psul-txt {
			margin: 0 0 0 12px;

			h5 {
				color: #0D011E;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-align: left;
			}
		}

	}

	.psul-cnt {
		.psul-inrcnt {
			.ulinr {
				margin: 0 0 15px 0;
			}
        }
	}


	.keyfeature-sec {

		padding: 0 0 30px 0;

		h5 {
			color: #111827;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 26px;
		}

		.keyfeature-inrcnt {
			padding: 25px 0;
		}

		p {
			color: rgba(17, 24, 39, 0.60);
			text-align: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 26px;
		}

		.keyfeature-img {
			text-align: center;
			margin: 30px 0 70px 0;
		}

	}

	.tokenomics-sec {
		.tokenomics-cnt {

			&.tokenomics-right {
				.tokenomice-grid {
					grid-template-columns: auto 30px;

					.token-cnt {
						text-align: right;
						padding: 0 20px 0 0;

						h4 {
							&::before {
								right: 0;
							}
						}

					}
				}
			}

			&.tokenomics-left {
				.tokenomice-grid {
					grid-template-columns: 30px auto;

					.token-cnt {
						text-align: left;
						padding: 0 0 0 20px;

						h4 {
							&::before {
								left: 0;
							}
						}

					}
				}
			}

			.tokenomice-grid {
				display: grid;
				border-radius: 4px;
				border: 1px solid #EAEAEA;
				background-color: #FFF;
				box-shadow: 0px 6px 54px 0px #E2F0FF;
				backdrop-filter: blur(5px);
				min-height: 115px;
				margin: 0 0 25px 0;
				padding: 20px;

				.token-cnt {
					h4 {
						color: #111827;
						font-size: 15px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						position: relative;
						padding: 0 0 10px 0;
						margin: 0 0 15px 0;

						&::before {
							content: '';
							position: absolute;
							bottom: 0;
							width: 40px;
							height: 1px;
							background-color: #456AFF;
						}


					}

					p {
						color: #111827;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 150%;
						margin: 0;
					}
				}


			}
		}
	}


	@media only screen and (max-width: 767px) {

		h2 {
			font-size: 32px;
			line-height: 40px;
		}

	}

	@media only screen and (max-width: 991px) {

		.tokenomics-sec .tokenomics-cnt .tokenomice-grid {
			max-width: 300px;
			margin: 0 auto 25px auto;
		}

	}

}