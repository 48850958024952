
.nftpage-content 
{
    .nftbanner-sec 
    {
        background-image: url('../images/banner-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        min-height: 410px;
        padding: 30px 0 30px 0;

      

    }
    h1 
    {
        color: #FFF;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
    }
    
    h2 
    {
        color: #111827;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 70px;
    }

    h3
    {
        color: #111827;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }

    h5 
    {
        color: #111827;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    h6 
    {
        color: #111827;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
    }

    p 
    {
        color: rgba(17, 24, 39, 0.60);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }

    .nft-inrbox 
    {
        margin: 0 0 30px 0;


        ul 
        {
            padding: 0;
            margin: 20px 0 0 0;
            list-style-type: none;

            li 
            {
                position: relative;
                color: rgba(17, 24, 39, 0.60);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                padding: 0 0 0 15px;
                margin: 0 0 15px 0;

                &::before 
                {
                    content: '';
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 7px;
                    height: 7px;
                    background: rgba(17, 24, 39, 0.60);
                    border-radius: 50%;
                }

            }

        }


    }

    @media only screen and (max-width: 767px) {
    
        h1 
            {
                font-size: 30px;
                line-height: 45px;
                text-align: center;
            }
        

        h2 
        {
            font-size: 30px;
            line-height: 40px;
        }
        h3
        {
            font-size: 25px;
            line-height: 40px;
        }

    }

}